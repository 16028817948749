import axios from "axios"; 
import { toast } from "react-hot-toast";
import { API_BASE_URL } from "../Utils/BaseUrl";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.data.status === 401) {
      toast.error(error.response.data.message, { id: "etrr01" });
      localStorage.clear();
      window.location.href = "/sign-in";
      throw error;
    } else {
      toast.error(error.response.data.message, { id: "etrr01" });
      throw error;
    }

  }
);

export const postApiWithoutToken = (path, data) => {
  return axios.post(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  });
};

export const putApiWithoutToken = (path, data) => {
  return axios.put(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postApiWithToken = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const  getApiWithToken = (path) => {
  const token = localStorage.getItem("token");

  return axios.get(API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const imgUploadApi = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.post(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getApiWithoutToken = (path) => {
  return axios.get(API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const putApiWithToken = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.put(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

// export const postLoginApi = (path, data) => {
//   // const token = localStorage.getItem("token");

//   return axios.post(API_BASE_URL + path, data, {
//     headers: {
//       "Content-Type": "application/json",
//       // Authorization: `Bearer ${token}`,
//     },
//   });
// };
