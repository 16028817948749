import { useFormik } from "formik";
import { LoginValidation, forgetEmailValidation, forgotPasswordValidation, profileValidation } from "../Validations";
import { forgotPasswordApi, loginApi, resetPasswordApi, updateProfile } from "../../Api";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AppContext from "../../app_context";

export const ForgotPasswordForm = () => {
	return useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: forgetEmailValidation,
		onSubmit: async (values) => {
			try {
				const response = await forgotPasswordApi(values);
				if (response.status === 200) {
					toast.success(response?.data?.message, { id: "0012" });
				}
			} catch (err) {
				toast.error(err.response.data.message, { id: "etrr01" });
			}
		},
	});
};

export const Loginform = (setKeepLoggIn) => {
	const navigate = useNavigate();
	return useFormik({
		initialValues: {
			email: "",
			password: "",
			keepLoggedIn: false,
		},

		validationSchema: LoginValidation,

		onSubmit: async (values) => {
			const { email, password } = values;
			try {
				setKeepLoggIn({ keepLogIn: values.keepLoggedIn, loading: true });
				const res = await loginApi({ email, password });
				if (res.status === 200 || res.status === 201) {
					if (res?.data?.accessToken === false) {
						setKeepLoggIn({ loading: false });
						toast(res?.data?.message, { icon: "⚠️", id: "1102" });
					} else {
						setKeepLoggIn({ loading: false });
						toast.success(res?.data?.message, { id: "001" });
						localStorage.setItem("token", res.data.accessToken);
						navigate("/", { replace: true });
					}
				}
			} catch (error) {
				setKeepLoggIn({ loading: false });
				toast.error(error?.response?.data?.message, { id: "etrr01" });
			}
		},
	});
};

export const ProfileForm = () => {
	const { fetchDriverProfile } = useContext(AppContext);
	// const { fetchDriverProfile } = appContext;

	return useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			phone: "",
			email: "",
			address: "",
			city: "",
			state: "",
			zipCode: "",
			drivingLicenseNo: "",
			drivingLicensePicture: "",
			drivingLicensePictureBack: "",
			profilePicture: "",
		},
		validationSchema: profileValidation,
		onSubmit: async (values) => {
			try {
				const response = await updateProfile(values);
				if (response.status === 200) {
					toast.success(response.data.message, { id: "001" });
					fetchDriverProfile();
					// window.history.back();
				}
			} catch (err) {
				toast.error(err?.response?.data?.message, { id: "etrr01" });
			}
		},
	});
};

export const ResetPwdForm = (location) => {
	const navigate = useNavigate();

	return useFormik({
		initialValues: {
			password: "",
			confirmPassword: "",
		},
		validationSchema: forgotPasswordValidation,
		onSubmit: async (values) => {
			try {
				// const res = await resetPasswordApi({ ...values, code: location?.state.token || "" })
				const res = await resetPasswordApi({ password: values.password, code: location?.search.split("=")[1] });
				if (res.status === 200 || Response.status === 201) {
					toast.success(res?.data.message, { id: "0014" });
					navigate("/my-thankyou");
				}
			} catch (err) {
				toast.error(err?.response?.data?.message, { id: "etrr01" });
			}
		},
	});
};
