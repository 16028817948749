import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Map from "../../../Components/Map";
import { assignBucketUrl, fetchGig, getLastElementId2 } from "../../../HelperFunctions";
import { updateLatLong, updateLongLat } from "../../../Api";
import Header from "../../../Components/Layout/Header";
import { useParams } from 'react-router-dom';

const LiveStatusMap = () => {
  const [gigState, setGigState] = useState("");
  const [liveLongLat, setLiveLongLat] = useState("");
  const data = useParams();
   

  const pickData = gigState?.gigData?.data;
  const dealer = gigState?.gigData?.data;


  const from = pickData?.pickupAddress;
  const to = pickData?.dropOffAddress;
  const add = pickData?.dropOffAddress;
  const dropAdd = add;
  const navigate = useNavigate();
  const orderId = getLastElementId2();
  const handleArrived = () => {
    if (gigState?.gigData?.data?.photosAfterDeliver?.length > 0) {
      navigate(`/`);
    } else {
      navigate(`/drop-details/${pickData?.orderId}`, { replace: true });
    }
  }
  const handleNav = () =>
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${dropAdd}&travelmode=driving`, "_blank");

  // const getLocationAndSendAPI = () => {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       setLiveLongLat({
  //         lattitude: position.coords.latitude?.toString(),
  //         longitude: position.coords.longitude?.toString(),
  //         orderId,
  //       });
  //     },
  //     (error) => {
  //       console.error("Error getting location:", error);
  //     }
  //   );
  // };

  // const fetchApiData = async () => {
  //   await updateLongLat(liveLongLat);
  // };

  useEffect(() => {
    fetchGig(orderId, setGigState);
    // getLocationAndSendAPI();
    // const interval = setInterval(getLocationAndSendAPI, 5 * 60 * 1000);

    // return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   if (liveLongLat !== "") {
  //     fetchApiData();
  //   }
  // }, [liveLongLat]);

  async function getLocation() {
		
		if (navigator.geolocation) {
			 navigator.geolocation.getCurrentPosition(
			  handleSuccess,
			  handleError
			);
		  } else {
			alert('Geolocation is not supported by your browser');
		  }
	  }
	  const handleSuccess = async (position) => {
		  setLiveLongLat({
        lattitude: position.coords.latitude,
        longitude: position.coords.longitude,
        orderId,
      });
		 await updateLatLong({
			orderId:data?.orderId,
			currentLatitude:position.coords.latitude,
			currentLongitude:position.coords.longitude
		})
	  };
	
	  const handleError = (error) => {
		alert(error.message);
	  };
	
  useEffect(()=>{
   const timer = setInterval(()=>{
    getLocation();
   },30000)
   return () => clearInterval(timer);
  },[])

 

  return (

    <>
      <div className="position-relative container g-0 p-0">
        <Header />
      </div>
      {pickData && (
        <>
          <div className='container mt-5' style={{ height: "60vh" }}>
            <Map from={from} to={to} liveLongLat={liveLongLat}/>
            <div>

              <div className=''>
                <div className='card mb-3 border-0' style={{ boxShadow: "0px 0px 3px #E0E0E0" }}>
                  <div className='card-body'>
                    <div >
                      <h6 className="mb-2 fw-bold dark-clr">Dealer Information</h6>
                      <div className='my-2' style={{ height: "55px", width: "55px" }}>
                        <img
                          src={assignBucketUrl(dealer?.profilePicture)}
                          onError={(event) => {
                            event.target.src =
                              "https://driver101.s3.us-west-1.amazonaws.com/upload/files/Portrait_Placeholder.png";
                            event.onerror = null;
                          }}
                          alt='#'
                          style={{ height: "100%", width: "100%", objectFit: "cover" }}
                          className='rounded-2'
                        />
                      </div>
                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Name</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{`${dealer?.name}`}</div>
                      </div>
                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Phone </div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{`(${dealer?.customerPhone?.slice(
                          2,
                          5
                        )}) ${dealer?.phone?.slice(5, 8)}-${dealer?.phone?.slice(8, 12)}`}</div>
                      </div>
                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Address</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{dealer?.address}</div>
                      </div>
                      
                      <hr />
                      <h6 className="mb-2 fw-bold dark-clr">Customer Information</h6>
                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Name</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{dealer?.customerFullName || "-"}</div>
                      </div>
                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Phone </div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{`(${dealer?.customerPhone?.slice(
                          2,
                          5
                        )}) ${dealer?.customerPhone?.slice(5, 8)}-${dealer?.customerPhone?.slice(8, 12)}`}</div>
                      </div>
                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Email</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{dealer?.customerEmail || "-"}</div>
                      </div>
                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Other Information</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{dealer?.customerOtherInfos || "-"}</div>
                      </div>
                      <hr />
                      <h6 className="mb-2 fw-bold dark-clr">Vehicle Information</h6>

                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Car make</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{dealer?.vehicleMake || "-"}</div>
                      </div>
                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Car model</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{dealer?.vehicleModel || "-"}</div>
                      </div>
                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Car color</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{dealer?.vehicleColor || "-"}</div>
                      </div>{" "}
                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Other information</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{dealer?.vehicleOtherInformation || "-"}</div>
                      </div>

                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Pickup address</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{dealer?.pickupAddress || "-"}</div>
                      </div>

                      <div className='row  mb-2'>
                        <div className='col-5 text-fade p-12'>Dropoff address</div>
                        <div className='col-1 p-12 text-fade'>:</div>
                        <div className='col-5 text-black fw-semibold p-14 '>{dealer?.dropOffAddress || "-"}</div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                type='submit'
                onClick={() => handleNav()}
                className=' my-3 button text-white  py-3 fw-semibold '
                style={{ width: "48%", margin: "0 1%" }}>
                Go to Navigation
              </button>
              <button
                type='button'
                onClick={() => handleArrived()}
                className=' my-3 button text-white  py-3 fw-semibold '
                style={{ width: "48%", margin: "0 1%" }}>
                Arrived ?
              </button>
            </div>
          </div>
        </>
      )}
    </>

  );
};

export default LiveStatusMap;
