import React, { createContext, useContext, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import PRoute from "./PrivateRoute";
import { useLocation, useRoutes } from "react-router-dom";
import LogoutModal from "../Components/Modals/LogoutModal";
import ResetPassword from "../Pages/Account/ResetPassword";
import ForgetPassword from "../Pages/Account/ForgetPassword";
import ResetThankyou from "../Pages/Account/ResetPassword/Reset_Thankyou";
import Dashboard from "../Pages/Dashboard";
import SignIn from "../Pages/Account/Login";
import SignUp from "../Pages/Account/Register";
import Gas from "../Pages/Gas";
// import GigsNearMe from "../Pages/GigsNearMe";
import VerificationPending from "../Pages/Account/VerificationPending";
import Gig from "../Pages/Gig";
import DropDetails from "../Pages/DropDetails";
import PickupDetails from "../Pages/PickupDetails";
import MyProfile from "../Pages/Account/My_Profile";
import Error404 from "../Components/Error404";
import PublicRoute from "./PublicRoute";
import MyBooking from "../Pages/MyBooking";
import Chat from "../Pages/Chat";
import DealerDetails from "../Pages/Gig/DealerDetails";
import { fetchGig, getLastElementId2 } from "../HelperFunctions";
import LiveStatusMap from "../Pages/PickupDetails/LiveStatusMap";
import AppContext from "../app_context";
import GigList from "../Pages/gigList/GigList";
import Gigview from "../Pages/Gig/Gigview";

export const GigContext = createContext();

export default function MainRouter() {
  let location = useLocation();
  const { gigState, setGigState, pageId, setPageId } = useContext(AppContext);
  const orderId = getLastElementId2();

  useEffect(() => {
    const isBookedNav = Boolean(
      window.location.pathname.includes("pickup-details") ||
        window.location.pathname.includes("gig-details") ||
        window.location.pathname.includes("drop-details")
    );
    if (isBookedNav) {
      fetchGig(orderId, setGigState);
    }
  }, [location]);

  const routes = useRoutes([
    {
      path: "/*",
      element: (
        <PRoute>
          <Error404 />
        </PRoute>
      ),
    },
    {
      path: "/gas",
      element: (
        <PRoute>
          <Gas />
        </PRoute>
      ),
    },
    {
      path: "/chat",
      element: (
        <PRoute>
          <Chat />
        </PRoute>
      ),
    },
    {
      path: "/my-bookings",
      element: (
        <PRoute>
          <MyBooking />
        </PRoute>
      ),
    },
    {
      path: "/live-status/:orderId",
      element: (
        <PRoute>
          <LiveStatusMap />
        </PRoute>
      ),
    },

    {
      path: "/drop-details/:orderId",
      element: (
        <PRoute>
          <DropDetails />
        </PRoute>
      ),
    },
    {
      path: "/pickup-details/:orderId",
      element: (
        <PRoute>
          <PickupDetails />
        </PRoute>
      ),
    },
    {
      path: "/gig-details/:orderId/*",
      element: (
        <PRoute>
          <Gig />
        </PRoute>
      ),
    },
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/my-profile",
      element: (
        <PRoute>
          <MyProfile />
        </PRoute>
      ),
    },
    {
      path: "/dealer-details",
      element: (
        <PRoute>
          <DealerDetails />
        </PRoute>
      ),
    },

    {
      path: "/forgot-password",
      element: (
        <PublicRoute>
          <ForgetPassword />
        </PublicRoute>
      ),
    },
    {
      path: "/reset-password",
      element: (
        <PublicRoute>
          <ResetPassword />
        </PublicRoute>
      ),
    },

    { path: "/verification-pending", element: <VerificationPending /> },
    {
      path: "/sign-in",
      element: (
        <PublicRoute>
          <SignIn />
        </PublicRoute>
      ),
    },
    {
      path: "/sign-up",
      element: (
        <PublicRoute>
          <SignUp />
        </PublicRoute>
      ),
    },
    { path: "/my-thankyou", element: <ResetThankyou /> },
    {path:"/giglist", element:<GigList/>},
    { path:"/viewgig", element:<Gigview/>}

  ]);

  return (
    <>
      <LogoutModal />
      <GigContext.Provider value={{ gigState, setGigState, pageId, setPageId }}>{routes}</GigContext.Provider>
      <Toaster position='top-center' reverseOrder={false} />
    </>
  );
}
