import React, { useCallback } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Select from "react-select";
import TimePicker from "react-time-picker";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { components } from "react-select";
import { toast } from "react-hot-toast";
import { getLocationInputApi } from "../../Api";

const SearchGigs = ({ formik, dashState, setDashState, handleFollow }) => {
  const followAddValues = { from: formik?.values?.from, to: formik?.values?.to };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>Search your address</span>
      </components.NoOptionsMessage>
    );
  };

  const handleChangeAdd = (Add, type) => {
    if (type === "from") {
      if (Add.value === formik.values.to) {
        toast("Can't be same as drop address", { icon: "⚠️" });
      } else {
        formik.setFieldValue("from", Add.value);
      }
    } else if (type === "to") {
      if (Add.value === formik.values.from) {
        toast("Can't be same as pickup address", { icon: "⚠️" });
      } else {
        formik.setFieldValue("to", Add.value);
      }
    }
  };

  const handleLocationChange = async (search) => {
    try {
      const res = await getLocationInputApi(search);

      if (res.status === 200 && res?.data?.length) {
        const rd = res.data.map((e) => {
          return {
            label: e.name + " " + e.formatted_address,
            value: e.name + " " + e.formatted_address,
          };
        });
        return rd;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const fetchLocationArray = useCallback(
    debounce((inputText, callback) => {
      handleLocationChange(inputText).then((options) => callback(options));
    }, 500),
    []
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='container'>
        <div className='row mb-2'>
          {/* <div className='mt-2 col-lg-6 col-md-6 col-6'>
             <input
              value={formik.values.from}
              name='from'
              onChange={(e) => formik.handleChange(e)}
              type='text'
              className='form-control py-2 p-14'
              placeholder='From'
            />
          </div> */}

          <div className='mt-2 col-lg-6 col-md-6 col-6'>
            <AsyncSelect
              components={{ NoOptionsMessage }}
              placeholder='From?'
              cacheOptions
              loadOptions={fetchLocationArray}
              onChange={(Add) => {
                handleChangeAdd(Add, "from");
              }}
              defaultOptions
              name='from'
              className={`form-control p-0`}
              value={
                Boolean(formik.values?.from) && {
                  label: formik.values.from,
                  value: formik.values.from,
                }
              }
              onFocus={() => {
                formik.setFieldValue("from", "");
              }}
            />
          </div>

          {/* <div className=' mt-2 col-lg-6 col-md-6 col-6'>
            <input
              value={formik.values.to}
              name='to'
              onChange={(e) => formik.handleChange(e)}
              type='text'
              className='form-control py-2 p-14'
              placeholder='To'
            />
          </div> */}

          <div className='mt-2 col-lg-6 col-md-6 col-6'>
            <AsyncSelect
              components={{ NoOptionsMessage }}
              placeholder='To?'
              cacheOptions
              loadOptions={fetchLocationArray}
              onChange={(Add) => {
                handleChangeAdd(Add, "to");
              }}
              defaultOptions
              name='to'
              className={`form-control p-0`}
              value={
                Boolean(formik.values?.to) && {
                  label: formik.values.to,
                  value: formik.values.to,
                }
              }
              onFocus={() => formik.setFieldValue("to", "")}
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div className=' my-1 col-lg-3 col-md-3 col-sm-6 col-6'>
            <DatePicker
              selected={formik.values.pickUpDate}
              name='pickUpDate'
              onChange={(e) => formik.setFieldValue("pickUpDate", e)}
              className='form-control py-2 p-14'
              isClearable={formik.values.pickUpDate === "" ? false : true}
              placeholderText='Pickup date'
              dateFormat='P'
            />
          </div>
          <div className=' my-1  col-lg-3 col-md-3 col-sm-6 col-6'>
            <div className='position-relative'>
              <TimePicker
                className='form-control pt-04rem p-2 py-2 position-absolute'
                format='h:m a'
                openClockOnFocus={false}
                hourPlaceholder='hh'
                minutePlaceholder='mm'
                onChange={(e) => formik.setFieldValue("pickUpTime", e)}
                value={formik.values.pickUpTime}
                name='pickUpTime'
                minuteAriaLabel='Minute'
                clockIcon={null}
              />
            </div>
          </div>
          <div className='  my-1  col-lg-6 col-md-6 col-sm-12 '>
            <input
              value={formik.values.payout}
              name='payout'
              onChange={(e) => formik.handleChange(e)}
              type='text'
              className='form-control py-2 p-14'
              placeholder='Payout'
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-6'>
            <div className='dropdown mb-3'>
              <Select
                className='basic-single form-control p-0'
                placeholder={`Available gigs`}
                defaultValue={formik.values.filter}
                isDisabled
                isSearchable={false}
                name='color'
                options={"filterOptions"}
                onChange={(e) => {
                  formik?.setFieldValue("filter", e.value);
                  setDashState((v) => ({
                    ...v,
                    searchFilter: { ...dashState?.searchFilter, filter: e.value },
                  }));
                }}
              />
            </div>
          </div>
          <div className='col-6'>
            <Link
              onClick={() => handleFollow(followAddValues)}
              className='form-control py-2 d-flex justify-content-between fw-semibold dddd'
              style={{ textDecoration: "none", background: "rgba(68, 136, 191, 0.2)" }}>
              Follow
            </Link>
          </div>
          <div className='my-3'>
            <button type='submit' className='button btn-primary w-100 py-3 h4 p-18 fw-normal'>
              Search gigs
            </button>
          </div>{" "}
        </div>
      </div>
    </form>
  );
};

export default SearchGigs;
