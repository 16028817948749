import React, { useContext } from "react";
import Layout from "../../Components/Layout";
import img1 from "../../Assets/images/Mask Group 11786.png";
import img2 from "../../Assets/images/phone.png";
import img3 from "../../Assets/images/Mask Group 11787.png";
import img4 from "../../Assets/images/Mask Group 11788.png";
import { gigContext } from ".";
import { useNavigate } from "react-router-dom";

const Instructions = () => {
  const { orderId } = useContext(gigContext);
  const navigate = useNavigate();

  const acceptedTerms = () => {
    setTimeout(() => {
      navigate(`/pickup-details/${orderId}`, { replace: true });
    }, 200);
  };

  return (
    <Layout>
      <div className='main-content pb-5'>
        <div className=' container position-relative' style={{ height: "80vh" }}>
          <div className='pt-3'></div>
          <h5 className='fw-semibold'>Start driving</h5>
          <div className='d-flex align-items-center justify-content-center py-3 mb-3'>
            <div>
              <i className='bi bi-check-circle-fill h3 dark-clr'></i>
            </div>
            <div className='border-bottom' style={{ width: "50px" }}></div>
            <div>
              <i className='bi bi-check-circle-fill h3 dark-clr'></i>
            </div>
            <div className='border-bottom' style={{ width: "50px" }}></div>
            <div>
              <i className='bi bi-check-circle-fill h3 dark-clr'></i>
            </div>
            <div className='border-bottom' style={{ width: "50px" }}></div>
            <div>
              <i className='bi bi-check-circle-fill text-muted h3 opacity-25'></i>
            </div>
          </div>
          <div className='d-flex align-items-center py-2 border-bottom'>
            <img src={img1} className='mx-2' alt='' height='50px' width='50px' />
            <p className='m-0 ms-3 fw-semibold p-16'>No Speeding</p>
          </div>
          <div className='d-flex align-items-center py-2 border-bottom'>
            <img src={img2} className='mx-2' alt='' height='50px' width='50px' />
            <p className='m-0 ms-3 fw-semibold p-16'>Notify the customer</p>
          </div>
          <div className='d-flex align-items-center py-2 border-bottom'>
            <img src={img3} className='mx-2' alt='' height='50px' width='50px' />
            <p className='m-0 ms-3 fw-semibold p-16'>No food or drinks</p>
          </div>
          <div className='d-flex align-items-center py-2 border-bottom'>
            <img src={img4} className='mx-2' alt='' height='50px' width='50px' />
            <p className='m-0 ms-3 fw-semibold p-16'>No stopovers</p>
          </div>
          <div className='container1 mb-5 mt-4'>
            <input
              type='checkbox'
              // onClick={() => {
              //   confirmBooking(orderId);
              // }}
              onClick={() => acceptedTerms()}
            />
            <span className='text-bold'> I have read and accepted the conditions.</span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Instructions;
