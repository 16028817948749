import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../app_context";

function LogoutModal() {
  const appContext = useContext(AppContext);
  const { logout } = appContext;
  const navigate = useNavigate();

  return (
    <div className='modal fade' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content p-4 text-center'>
          <div className=''>
            <h1 className='modal-title fs-5 fw-bold' id='exampleModalLabel'>
              Log Out?
            </h1>
          </div>
          <div className='modal-body py-3 ps-0'>Are you sure you want to log out?</div>
          <div className='' style={{ display: " flex", justifyContent: "space-evenly" }}>
            <button
              type='button'
              className=' text-white dark-color py-2 px-xs-5 px-4 border-0 '
              data-bs-dismiss='modal'
              onClick={() => {
                // logoutOnJwtExpire()
                logout();
                navigate("/sign-in");
              }}>
              Log&nbsp;out
            </button>
            <button type='button' className='text-fade py-2 px-xs-5 px-4 border-0 ms-1' data-bs-dismiss='modal'>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoutModal;
