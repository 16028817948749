import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/images/Mask Group 2259.png";
import menu from "../../Assets/images/Group 3842.svg";
import AppContext from "../../app_context";

const Header = () => {
	const navigate = useNavigate();
	const location = useLocation();
	console.log()
	const { pageId, orderId } = useContext(AppContext);

	return (
		<>
			<div
				className='offcanvas offcanvas-start bg-navy-blue container'
				data-bs-scroll='true'
				tabIndex='-1'
				id='offcanvasWithBothOptions'
				aria-labelledby='offcanvasWithBothOptionsLabel'>
				<div className='offcanvas-header'>
					<div className='offcanvas-title m-auto my-4' id='offcanvasWithBothOptionsLabel' style={{ height: " 80px" }}>
						<img src={logo} alt='' style={{ width: "100%", height: "100%" }} />
					</div>
				</div>
				<div className='offcanvas-body'>
					<div className='menue mb-3'>
						<button
							className='collapsed sidebutton btn w-100 d-flex align-items-center justify-content-between '
							onClick={() => navigate("/my-profile")}
							type='button'>
							<span>
								<i className='bi bi-person me-2'></i>My Profile
							</span>
						</button>
						<div className='collapse' id='collapseExample1'>
							<div className='button-drop card card-body bg-transparent border-0 border-top-0' style={{ boxShadow: "0px 0px 3px -1px #ffff" }}>
								<div className='form-check'>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='past' />
										<label className='form-check-label text-white ms-2' htmlFor='past'>
											Past
										</label>
									</div>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='future' />
										<label className='form-check-label text-white ms-2' htmlFor='future'>
											Future
										</label>
									</div>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='present' />
										<label className='form-check-label text-white ms-2' htmlFor='present'>
											Present
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='menue mb-3'>
						<button
							className='collapsed sidebutton btn w-100 d-flex align-items-center justify-content-between'
							type='button'
							data-bs-toggle='collapse'
							data-bs-target='#collapseExample2'
							aria-expanded='false'
							aria-controls='collapseExample'>
							<span>
								{" "}
								<i className='bi bi-gear me-2'></i>How it works
							</span>
							{/* <span className='text-muted arrow'>
								<i className='bi bi-chevron-right'></i>
							</span> */}
						</button>
						{/* <div className='collapse' id='collapseExample2'>
							<div className='button-drop card card-body bg-transparent border-0 border-top-0' style={{ boxShadow: "0px 0px 3px -1px #ffff" }}>
								<div className='form-check'>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='past' />
										<label className='form-check-label text-white ms-2' htmlFor='past'>
											Past
										</label>
									</div>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='future' />
										<label className='form-check-label text-white ms-2' htmlFor='future'>
											Future
										</label>
									</div>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='present' />
										<label className='form-check-label text-white ms-2' htmlFor='present'>
											Present
										</label>
									</div>
								</div>
							</div>
						</div> */}
					</div>
					<div className='menue mb-3'>
						<button
							className='collapsed sidebutton btn w-100 d-flex align-items-center justify-content-between'
							style={{ boxShadow: "0px 0px 3px -1px #ffff" }}
							type='button'
							data-bs-toggle='collapse'
							data-bs-target='#collapseExample3'
							aria-expanded='false'
							aria-controls='collapseExample'>
							<span>
								{" "}
								<i className='bi bi-car-front-fill me-2'></i>Drive
							</span>
							{/* <span className='text-muted arrow'>
								<i className='bi bi-chevron-right'></i>
							</span> */}
						</button>
						{/* <div className='collapse' id='collapseExample3'>
							<div className='button-drop card card-body bg-transparent border-0 border-top-0' style={{ boxShadow: "0px 0px 3px -1px #ffff" }}>
								<div className='form-check-sidebar'>
									<div className='my-2'>
										<input className='form-check-input-sidebar' type='radio' name='drive' id='past' />
										<label className='form-check-label text-white ms-2' htmlFor='past'>
											Past
										</label>
									</div>
									<div className='my-3'>
										<input className='form-check-input-sidebar' type='radio' name='drive' id='future' />
										<label className='form-check-label text-white ms-2' htmlFor='future'>
											Future
										</label>
									</div>
									<div className='my-2'>
										<input className='form-check-input-sidebar' type='radio' name='drive' id='present' />
										<label className='form-check-label text-white ms-2' htmlFor='present'>
											Present
										</label>
									</div>
								</div>
							</div>
						</div> */}
					</div>
					<div className='menue mb-3'>
						<button
							className='collapsed sidebutton btn w-100 d-flex align-items-center justify-content-between '
							onClick={() => navigate("/chat")}
							type='button'>
							<span>
								<i className='bi bi-info-circle me-2'></i>Help
							</span>
						</button>
						{/* <button
              className='collapsed sidebutton btn w-100 d-flex align-items-center justify-content-between'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#collapseExample4'
              aria-expanded='false'
              aria-controls='collapseExample'>
              <span>
                {" "}
                <i className='bi bi-bell me-2'></i>Help
              </span>
              <span className='text-muted arrow'>
                <i className='bi bi-chevron-right'></i>
              </span>
            </button> */}
						<div className='collapse' id='collapseExample4'>
							<div className='button-drop card card-body bg-transparent border-0 border-top-0' style={{ boxShadow: "0px 0px 3px -1px #ffff" }}>
								<div className='form-check'>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='past' />
										<label className='form-check-label text-white ms-2' htmlFor='past'>
											Past
										</label>
									</div>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='future' />
										<label className='form-check-label text-white ms-2' htmlFor='future'>
											Future
										</label>
									</div>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='present' />
										<label className='form-check-label text-white ms-2' htmlFor='present'>
											Present
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='menue mb-3'>
						<button
							className='collapsed sidebutton btn w-100 d-flex align-items-center justify-content-between'
							type='button'
							data-bs-toggle='collapse'
							data-bs-target='#about'
							aria-expanded='false'
							aria-controls='collapseExample'>
							<span>
								{" "}
								<i className='bi bi-building me-2'></i>About Us
							</span>
							{/* <span className='text-muted arrow'>
								<i className='bi bi-chevron-right'></i>
							</span> */}
						</button>
						{/* <div className='collapse' id='about'>
							<div className='button-drop card card-body bg-transparent border-0 border-top-0' style={{ boxShadow: "0px 0px 3px -1px #ffff" }}>
								<div className='form-check'>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='past' />
										<label className='form-check-label text-white ms-2' htmlFor='past'>
											Past
										</label>
									</div>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='future' />
										<label className='form-check-label text-white ms-2' htmlFor='future'>
											Future
										</label>
									</div>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='present' />
										<label className='form-check-label text-white ms-2' htmlFor='present'>
											Present
										</label>
									</div>
								</div>
							</div>
						</div> */}
					</div>
					<div className='menue mb-3'>
						<button
							className='collapsed sidebutton btn w-100 d-flex align-items-center justify-content-between'
							type='button'
							data-bs-toggle='collapse'
							data-bs-target='#contact'
							aria-expanded='false'
							aria-controls='collapseExample'>
							<span>
								{" "}
								<i className='bi bi-envelope-at me-2'></i>Contact
							</span>
							{/* <span className='text-muted arrow'>
								<i className='bi bi-chevron-right'></i>
							</span> */}
						</button>
						{/* <div className='collapse' id='contact'>
							<div className='button-drop card card-body bg-transparent border-0 border-top-0' style={{ boxShadow: "0px 0px 3px -1px #ffff" }}>
								<div className='form-check'>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='past' />
										<label className='form-check-label text-white ms-2' htmlFor='past'>
											Past
										</label>
									</div>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='future' />
										<label className='form-check-label text-white ms-2' htmlFor='future'>
											Future
										</label>
									</div>
									<div>
										<input className='form-check-input' type='radio' name='drive' id='present' />
										<label className='form-check-label text-white ms-2' htmlFor='present'>
											Present
										</label>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</div>
				<div className='offcanvas-header'>
					<div className='w-100 mb-3'>
						<button className='logout border btn w-100 bg-transparent text-white fw-semibold py-2' data-bs-toggle='modal' data-bs-target='#exampleModal'>
							<i className='bi bi-box-arrow-left me-2 h5'></i>Log out
						</button>
					</div>
				</div>
			</div>
			<div
				style={{ zIndex: "1" }}
				className='w-100 dark-color container  d-flex align-items-center justify-content-between text-align-center  position-fixed top-0 '>
				<div className=' d-flex justify-content-center align-items-center'>
				
					{!(location.pathname === "/" ) && <div style={{ cursor: "pointer" }} className='me-1' onClick={() => navigate(-1)}>
						<i className='bi bi-chevron-left h1 text-white ms-2'></i>
					</div>}
					{/* {window.location.pathname.startsWith("/gig-details") ? (
						<div
							style={{ cursor: "pointer" }}
							className='me-1'
							onClick={() => (pageId === 1 ? navigate("/") : navigate(`/gig-details/${orderId}/${pageId - 1}`))}>
							<i className='bi bi-chevron-left h1 text-white ms-2'></i>
						</div>
					) : (
						""
					)} */}
					<div style={{ width: " 60px" }} className=' text-align-center py-2 '>
						<Link to='/'>
							<img src={logo} alt='#' style={{ width: "100%" }} />
						</Link>
					</div>
				</div>

				<div
					className='text-white  text-align-center ps-3 me-3 h4'
					style={{cursor: 'pointer'}}
					data-bs-toggle='offcanvas'
					data-bs-target='#offcanvasWithBothOptions'
					aria-controls='offcanvasWithBothOptions'>
					{/* <i className='bi bi-list fs-1'></i> */}
					<img src={menu} alt='#' style={{ width: "100%" }} />
				</div>

				{/* <div className=' d-flex justify-content-evenly align-items-center me-2'>
            <i className='bi bi-search text-white h4'></i>
          </div> */}
			</div>
		</>
	);
};

export default Header;
