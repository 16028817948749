import React, { useCallback, useState } from 'react'
import { Loader } from "../../Utils/Loader";
import { components } from "react-select";
import { debounce } from "lodash";

import AsyncSelect from "react-select/async";
import Layout from '../../Components/Layout';
import { useNavigate } from 'react-router-dom';
import { getGig } from '../../Api';
function GigList() {
  const [gigSearch, setGigSearch] = useState({ label: "search gig by id.." })
  const navigate = useNavigate();
    const NoOptionsMessage = (props) => {
      return (
        <components.NoOptionsMessage {...props}>
          <span className='custom-css-class'>Data Not Found</span>
        </components.NoOptionsMessage>
      );
    };

  const handleSearchGig = async (search) => {
    if (search) {
      try {
        setGigSearch({ label: search })
        const res = await getGig(search);
        if (res.status === 200) {
          return res.data?.orderId ? [{
            label: res.data?.orderId,
            value: res.data,
          }] : null
        }
        return null
      } catch (error) {
        return null;
      }
    }else{
      setGigSearch({ label: search })
    }
  };
  
  const gigDetailbySearching = useCallback(
    debounce((inputText, callback) => {
      handleSearchGig(inputText).then((options) => callback(options));
    }, 500),
    []
  );
  
  return (
    <Layout>
      <>
        <div className='container px-3 mb-4 pt-3 mt-5'> 
          <h3 className='fw-semibold mb-4'>Search Gigs</h3>
          {<><form >
            <AsyncSelect
              components={{ NoOptionsMessage }}
              className="border"
              // defaultInputValue="Select your location"
              placeholder='search gig by id..'
              // styles={SelectStyle}
              cacheOptions
              loadOptions={gigDetailbySearching}
              onChange={(f) => {
                return f?.value?.orderId ? navigate(`/gig-details/${f?.value?.orderId}/1`) : null
              }}
              value={gigSearch?.label || ""}
              defaultOptions
              name='pickupAddress'
              onFocus={() => {
                setGigSearch({ label: "search gig by id.." })
              }}
            />
          </form>
          </>
          }
        </div>
      </>
    </Layout>
  )
}

export default GigList