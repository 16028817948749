import * as yup from "yup";

const dlRegex =
  /^(?:(?=[A-Za-z0-9-]{6}$)[A-Za-z0-9]*-[A-Za-z0-9]*|[A-Za-z0-9-]{7}|[A-Za-z0-9-]{8}|[A-Za-z0-9-]{9}|[A-Za-z0-9-]{10}|[A-Za-z0-9-]{11}|[A-Za-z0-9-]{12}|[A-Za-z0-9-]{13}|[A-Za-z0-9-]{14}|[A-Za-z0-9-]{15}|[A-Za-z0-9-]{16}|[A-Za-z0-9-]{17})$/;

export const LoginValidation = yup.object({
  email: yup.string().email("Invalid email").required("Email can't be empty"),
  password: yup.string().required("Password can't be empty"),
});

export const pickValidation = yup.object({
  photos: yup.array().of(yup.string()).min(6, "Images should be atleast 6").required("This field is required"),
  mileage: yup.number().required("This field is required").integer().typeError("Please enter a valid number"),
  orderId: yup.string(),
  type: yup.string(),
});
export const dropValidation = yup.object({
  photos: yup.array().of(yup.string()).min(6, "Images should be atleast 6").required("This field is required"),
  mileage: yup.number().required("This field is required").integer().typeError("Please enter a valid number"),
  orderId: yup.string(),
  type: yup.string(),
});

export const registerValidation1 = yup.object({
  firstName: yup
    .string()
    .required("First Name can't be empty")
    .matches(/^[A-Za-z]+$/, "Please enter a valid First name")
    .trim(),
  lastName: yup
    .string()
    .required("Last Name can't be empty")
    .matches(/^[A-Za-z]+$/, "Please enter a valid Last name")
    .trim(),
  email: yup.string().email("Invalid email").required("Email can't be empty"),
  password: yup
    .string()
    .required("Password can't be empty")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .min(8, "Length should be eight"),

  confirmPassword: yup
    .string()
    .required("Confirm Password can't be empty")
    .oneOf([yup.ref("password")], "Passwords does not match"),
});

export const registerValidation2 = yup.object({
  phone: yup
    .string()
    .required("Phone Number can't be empty")
    .min(8, "Please enter a valid phone number")
    .max(12, "Please enter a valid phone number"),
  address: yup.string().required("Address can't be empty"),
  city: yup.string().required("City can't be empty"),
  state: yup.string().required("State can't be empty"),
  zipCode: yup
    .string()
    .required("Zip Code can't be empty")
    .matches(/^[A-Za-z0-9 ]*$/, "Please enter a valid Zip Code")
    .min(5, "Please enter a valid Zip Code")
    .max(6, "Please enter a valid Zip Code"),

  drivingLicenseNo: yup.string().required("Driver License can't be empty"),
  drivingLicensePicture: yup.string().required("Please upload front picture of driver license"),
  drivingLicensePictureBack: yup.string().required("Please upload back picture of driver license"),

  profilePicture: yup.string().required("Please upload a profile picture"),
});

export const profileValidation = yup.object({
  firstName: yup
    .string()
    .required("First Name can't be empty")
    .matches(/^[A-Za-z]+$/, "Please enter a valid First name")
    .trim(),
  lastName: yup
    .string()
    .required("Last Name can't be empty")
    .matches(/^[A-Za-z]+$/, "Please enter a valid Last name")
    .trim(),

  phone: yup.string().required("Phone Number can't be empty"),
});

export const forgetEmailValidation = yup.object({
  email: yup.string().email("Invalid email").required("Email can't be empty"),
});

export const forgotPasswordValidation = yup.object({
  password: yup
    .string()
    .min(8, "Use 8 or more characters with a mix of letters, numbers & symbols")

    .required("Password can't be empty")

    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  confirmPassword: yup
    .string()
    .required("Confirm Password can't be empty")
    .oneOf([yup.ref("password")], "Passwords does not match"),
});
