import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import pic from "../../../Assets/images/Icon feather-key.png";
import { ResetPwdForm } from "../../../Components/Forms";

function ResetForm() {
	const location = useLocation();

	const [passwordType, setPasswordType] = useState("password");
	const [confirmPasswordType, setConfirmPasswordType] = useState("password");

	const resetPasswordFormik = ResetPwdForm(location);

	return (
		<>
			<div className='my-5 align-items-center'>
				<div className='icon m-auto rounded-circle text-center d-flex align-items-center justify-content-center' style={{ height: "85px", width: "85px" }}>
					<img src={pic} alt='#' />
				</div>
			</div>
			<div className='text-center'>
				<h3 className='fw-bold'>Reset password?</h3>
				<p className='text-muted mb-4 h6'>Your new password must be different to previously used passwords.</p>
			</div>

			<form onSubmit={resetPasswordFormik.handleSubmit}>
				<div className='mb-4'>
					<div className='position-relative'>
						<input
							type={passwordType}
							className='form-control border-end-2 pe-5'
							id='exampleInputPassword1'
							placeholder='Enter your new password'
							name='password'
							value={resetPasswordFormik.values.password}
							onChange={resetPasswordFormik.handleChange}
						/>
						<i
							className={`bi ${passwordType === "password" ? "bi-eye-slash-fill" : "bi-eye-fill"} icon-mail  text-muted border-start-0`}
							style={{ cursor: "pointer" }}
							onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")}></i>
					</div>
					{Boolean(resetPasswordFormik.touched.password && resetPasswordFormik.errors.password) && (
						<p className='text-danger mt-2'>{resetPasswordFormik.errors.password}</p>
					)}
				</div>

				<div className='mb-4'>
					<div className='position-relative'>
						<input
							type={confirmPasswordType}
							className='form-control border-end-2 pe-5'
							id='exampleInputPassword2'
							placeholder='Enter your password again'
							name='confirmPassword'
							value={resetPasswordFormik.values.confirmPassword}
							onChange={resetPasswordFormik.handleChange}
						/>
						<i
							className={`bi ${confirmPasswordType === "password" ? "bi-eye-slash-fill" : "bi-eye-fill"} icon-mail  text-muted border-start-0`}
							style={{ cursor: "pointer" }}
							onClick={() => setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password")}></i>
					</div>
					{Boolean(resetPasswordFormik.touched.confirmPassword && resetPasswordFormik.errors.confirmPassword) && (
						<p className='text-danger mt-2'>{resetPasswordFormik.errors.confirmPassword}</p>
					)}
				</div>

				<div className='text-center mb-4'>
					<button type='submit' className='btn text-white w-100 py-3'>
						Reset password
					</button>
				</div>
			</form>
			<div className='text-center'>
				<h6 className='text-decoration-none text-muted'>
					<Link to='/sign-in' className='text-decoration-none text-muted'>
						<i className='bi bi-arrow-left'></i> Back to log in
					</Link>
				</h6>
			</div>
		</>
	);
}

export default ResetForm;
