import React, { useEffect } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";

const GigListDesktop = ({ val }) => {
	const { el, driverId, pageId, setPageId } = val;
	const navigate = useNavigate();

	const redirect = (status) => {
		if (status === "started") {
			navigate(`/live-status/${el?.orderId}`);
		} else {
			navigate(`/gig-details/${el?.orderId}/${pageId}`);
		}
	};

	useEffect(() => {
		if (el?.rideStatus === "booked" && el?.driverId === driverId) {
			setPageId(3);
		}

		if (el?.rideStatus !== "booked") {
			setPageId(1);
		}
	}, [el]);

	return (
		<div style={{ cursor: "pointer" }} onClick={() => redirect(el?.rideStatus)} className={`text-decoration-none d-sm-block d-none`}>
			<div className={` bg-white row gx-0 d-flex justify-content-between align-items-center mb-2 border-bottom  py-2 px-2`}>
				<div className='col-6'>
					<div>
						<p className='mb-1 p-12 text-capitalize text-muted '>
							<Moment format='ddd. MMM. D' date={el?.pickUpDateAndTime} />
						</p>
					</div>
					<div className='d-flex p-12 align-items-center mb-2'>
						<p className='m-0 fw-bold p'>
							<Moment format='LT ' date={el?.pickUpDateAndTime} />
						</p>
						<div className='border-top  text-center px-2' style={{ width: 50, height: 1, lineHeight: "0.6" }}>
							<span className='fw-bold' style={{ fontSize: 8 }}>
								{Math.round(el?.distance) + "mi"}
							</span>
						</div>
						<p className='m-0 fw-bold'>
							<Moment format='LT ' date={el?.dropOffTime || ""} />
						</p>
					</div>
					<div>
						<p style={{ textTransform: "initial" }} className='fw-semibold text-muted mb-1 p-12 pe-5'>
							{el?.pickupAddress}
						</p>
					</div>
					<div></div>
				</div>
				<div className='col-6 text-end'>
					{/* {isOnlyDel ? (
            <StarRatings
              rating={parseFloat(el?.rating)}
              starDimension='17px'
              starSpacing='1px'
              starRatedColor='#4488bf'
            />
          ) : isOnlyCncl ? (
            <div className='text-danger' style={{ color: "#a5a5a5", fontStyle: "italic" }}>
              Cancelled
            </div>
          ) : (
            ""
          )} */}
					<div className="text-end"><p className="fw-bold mb-1 p-12 text-dark">#{el?.orderId}</p></div>
					<div>
						<p className={`  danger-text  fw-semibold h6 mb-1`}>{"$" + el?.priceForDriver}</p>
					</div>

					<div>
						<p style={{ textTransform: "initial" }} className='fw-semibold text-muted mb-1 p-12 ps-5'>
							{el?.dropOffAddress}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GigListDesktop;
