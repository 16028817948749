import React, { useState } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import Form1 from "./Form1";
import Form2 from "./Form2";
import { useNavigate } from "react-router-dom";
import { registerApi } from "../../../Api";
import { registerValidation1, registerValidation2 } from "../../../Components/Validations";
import { WhiteLoader } from "../../../Components/Loaders";
import logo from "../../../Assets/images/Group 3621.png";
import _ from "lodash";

function SignUp() {
	const [step, setStep] = useState(1);
	const [checkLength, setCheckLength] = useState(false);
	const [signupLoader, setSignupLoader] = useState(false);
	const navigate = useNavigate();

	const formik1 = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			confirmPassword: "",
		},
		validationSchema: registerValidation1,

		onSubmit: (values) => {
			if (values.password.length < 8) {
				return setCheckLength(true);
			}
			setStep(2);
		},
	});

	const formik2 = useFormik({
		initialValues: {
			phone: "",
			address: "",
			city: "",
			state: "",
			zipCode: "",
			drivingLicenseNo: "",
			drivingLicensePicture: "",
			drivingLicensePictureBack: "",
			profilePicture: "",
		},
		validationSchema: registerValidation2,

		onSubmit: async (values) => {
			setSignupLoader(true);
			const payload = { ...formik1.values, ...values };
			const omittedPayload = _.omit(payload, ["confirmPassword", "signinPolicy"]);

			try {
				const response = await registerApi(omittedPayload);
				if (response.status === 200 || response.status === 201) {
					setSignupLoader(false);
					toast.success(response?.data?.message, { id: "0013" });
					navigate("/verification-pending");
				}
			} catch (err) {
				setSignupLoader(false);
				toast.error(err?.response?.data?.message, { id: "etrr01" });
			}
		},
	});

	const handleSubmit = () => {
		return;
	};

	return (
		<div className='container px-3'>
			<div className='py-3' style={{ height: "90px", width: "66px" }}>
				<img src={logo} alt='#' style={{ width: "100%" }} />
			</div>
			{step === 2 && (
				<i
					className='bi bi-arrow-left-circle-fill dark-clr h3 col-md-1'
					style={{ fontSize: "25px", cursor: "pointer" }}
					onClick={() => setStep((v) => v - 1)}></i>
			)}

			<div className='mb-4'>
				<h1 className='fw-bold mb-1 col-md-11'>Sign up</h1>
				{step === 1 && <p className='p-14 fw-semibold'>Sign Up Now and Start Earning:<br/>
				Become a Gig Driver Today!</p>}
			</div>

			{step === 1 && (
				<Form1 formik1={formik1} step={step} checkLength={checkLength} setCheckLength={setCheckLength}>
					<button type='submit' className='btn text-white w-100  py-3' onClick={() => handleSubmit(1)}>
						Next
					</button>
				</Form1>
			)}
			{step === 2 && (
				<Form2 formik1={formik1} formik2={formik2}>
					<button type='submit' className='btn text-white w-100 py-3'>
						{signupLoader ? <WhiteLoader /> : "Create an account"}
					</button>
				</Form2>
			)}

			{/* {Boolean(step === 0) && (
				<p className='text-muted text-center fs-6'>
					Already have an account?
					<Link to='/sign-in' className='fw-semibold ms-2'>
						Log in
					</Link>
				</p>
			)} */}
		</div>
	);
}

export default SignUp;
