import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dropped from "./Dropped";
import thankyou from '../../Assets/images/Image 159.png'
import { useFormik } from "formik";
import { dropValidation } from "../../Components/Validations";
import { toast } from "react-hot-toast";
import { toast as toast2 } from "react-toastify";
import _ from "lodash";
import { droppedSubmit, multiFileUploadApiDrop, rideEndSubmit } from "../../Api";
import { GigContext } from "../../MainRouter/MainRouter";
import Layout from "../../Components/Layout";
import dummyImg from "../../Assets/images/Group 3706.svg";
import { InputErrorMessage } from "../../Components/ErrorMessage";
import { handleFileRemoveForPickDrop, handleFileUploadForPickDrop, handleMapClick } from "../../HelperFunctions";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../../Utils/Loader";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import moment from "moment";

const Index = () => {
	const { gigState } = useContext(GigContext);
	const orderId = gigState?.gigData?.data?.orderId;

	let inputRef = useRef(null);
	const [selectedImage, setSelectedImage] = useState(null);

	const openImagePreview = (image) => {
		setSelectedImage(image);
	};

	const [imageFiles, setImageFiles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [images, setImages] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	let navigate = useNavigate();
	const pickData = gigState?.gigData?.data;
	const loader = gigState?.gigData?.loading;

	let dropFormik = useFormik({
		initialValues: {
			photos: "",
			mileage: "",
			orderId,
			type: "afterPick",
		},
		validationSchema: dropValidation,
		onSubmit: async (values) => {
			setLoading(true)
			const omitted = _.omit(values, ["photos", "type"]);
			const formdata = new FormData();

			const loadingToast = toast.loading("Loading...", { id: "0003" });
			imageFiles.forEach((file) => formdata.append("file", file));

			try {
				if (parseInt(pickData?.mileageBeforePick) >= parseInt(dropFormik.values.mileage)) {
					toast("Incorrect drop off mileage", { icon: "⚠️", id: "ioui" });
					toast.dismiss(loadingToast);
					setLoading(false)
				} else {
					const res = await droppedSubmit(omitted);
					const res2 = await multiFileUploadApiDrop(formdata, pickData?.orderId);

					if (res.status === 200 && res2.status === 200) {
						dropFormik?.setFieldValue("photos", res2?.data?.filePath);
						toast.dismiss(loadingToast);
						setLoading(true)
						const res3 = await rideEndSubmit({ orderId: values?.orderId, time:moment().format('ddd MMM D, (hh:mm A)') });
						if (res3.status === 200) {
							toast2(
								<div className='gigToast'>
									<div className=' modal-dialog modal-dialog-centered'>
										<div className='modal-content p-2 pb-3'>
											<div className='endToast'>🎉</div>
											<div className='text-center mb-2 pb-2'>
												<p className='m-0 fw-semibold text-dark' style={{ fontSize: "15px" }}>
													Thank you for being one of
												</p>
												<h4 className='m-0 fw-bold text-dark'> 101 Drivers</h4>
											</div>
											<button
												className='button btn-primary w-50 py-2 h5 fw-normal m-auto'
												onClick={() => {
													navigate("/",{replace:true});
													setModalOpen(false);
												}}>
												Continue
											</button>
										</div>
									</div>
								</div>,
								{ autoClose: false }
							);
							setModalOpen(true);
						}
					}
				}
			} catch (err) {
				setLoading(false)
				if (true) {
					toast2(
						<div className='gigToast'>
							<div className=' modal-dialog modal-dialog-centered'>
								<div className='modal-content p-2 pb-3 text-center'>
									<div className=''>
										<img
											src={thankyou}
											alt=" "
											style={{ width: "80%" }}
											className=""
										/>

									</div>
									<div className="text-center dark-clr mb-3">
										<h4 className="fw-bold  m-0 my-2">Thank you for </h4>
										<h4 className="fw-bold  m-0 my-2 ">using 101 Drivers</h4>
									</div>
									<button
										className='button btn-primary w-50 py-2 h5 fw-normal m-auto'
										onClick={() => {
											navigate("/");
											setModalOpen(false);
										}}>
										Continue
									</button>
								</div>
							</div>
						</div>,
						{ autoClose: false }
					);
					setModalOpen(true);
				}
				toast.dismiss(loadingToast);
				// setLoading(true)
			}
		},
	});

	useEffect(() => {
		let images = [],
			fileReaders = [];
		let isCancel = false;

		imageFiles.forEach((file) => {
			const fileReader = new FileReader();
			fileReaders.push(fileReader);
			fileReader.onload = (e) => {
				const { result } = e.target;
				if (result) {
					images = [...images, { result, file: file?.name }];
				}
				if (images.length === imageFiles.length && !isCancel) {
					setImages(images);
				}
			};
			fileReader.readAsDataURL(file);
		});
		if (dropFormik.values.photos === "") {
			setImages([]);
		}
		return () => {
			isCancel = true;
			fileReaders.forEach((fileReader) => {
				if (fileReader.readyState === 1) {
					fileReader.abort();
				}
			});
		};
	}, [imageFiles]);

	useEffect(() => {
		if (pickData?.orderId) {
			dropFormik.setFieldValue("orderId", pickData?.orderId);
		}
	}, [pickData]);

	return (
		<>
			<Layout>
				<ToastContainer className='foo myToaster' hideProgressBar={false} autoClose={false} transition={Zoom} closeOnClick={false} closeButton={<></>} />
				{modalOpen && <div className='overlay111' />}
				{loader ? (
					<>
						<hr style={{ height: "2rem" }} />
						<div className=' container text-center  '>
							<Loader />
						</div>
					</>
				) : (
					<form onSubmit={dropFormik?.handleSubmit}>
						<main className='container px-3'>
							<div className='row mb-3' style={{ marginTop: "5rem" }}>
								<div className='col-10'>
									<h6 className='fw-bold'>Pickup address</h6>
									<p className='p-13 fw-semibold text-muted'>{pickData?.pickupAddress}</p>
								</div>

								<div className='col-2 d-flex justify-content-end align-items-center'>
									<span style={{ cursor: "pointer" }} onClick={() => handleMapClick(pickData?.pickupAddress)}>
										<img src={dummyImg} alt='' />
									</span>
								</div>
							</div>
							<div className='row mb-5'>
								<div className='col-10'>
									<h6 className='fw-bold'>Delivery address</h6>
									<p className='p-13 fw-semibold text-muted'>{pickData?.dropOffAddress}</p>
								</div>
								<div className='col-2 d-flex justify-content-end align-items-center'>
									<span style={{ cursor: "pointer" }} onClick={() => handleMapClick(pickData?.dropOffAddress)}>
										<img src={dummyImg} alt='' />
									</span>
								</div>
							</div>
							{/* {formatPhoneNumber(pickData)} */}
							<div>
								<div className='d-flex justify-content-between align-items-center mb-3 '>
									<p className='text-14 fw-semibold text-muted p-16'>
										Photos after Arrival <span className='text-danger'> *</span>
									</p>
									<label htmlFor={`photos`}>
										<div id='addBtn1' className='changeFileBtn btn text-white py-1 h-100 rounded-2'>
											Add photos
										</div>
									</label>
								</div>
								<div className='d-flex mx-2 flex-wrap mb-3' style={{ height: "200px", overflowY: "auto" }}>
									{images?.length >= 1 &&
										images?.map((el, i) => {
											return (
												<div key={`drop${i}`} className='mb-2 me-2 position-relative imageSubmit-container'>
													<span
														style={{ cursor: "pointer", right: "0.5rem" }}
														className='position-absolute'
														onClick={() => handleFileRemoveForPickDrop(imageFiles, setImageFiles, i, dropFormik, inputRef)}>
														<i className='bi bi-x-circle me-2 crossCircle'></i>
													</span>
													<img
														src={el.result}
														alt=''
														className='img-fluid rounded'
														style={{ width: "100%", height: "100%", objectFit: "cover", border: "1px solid lightGrey", cursor: "pointer" }}
														onClick={() => openImagePreview(el?.result)}
													/>
													<div className='modalPrev'>
														{selectedImage && <Lightbox image={selectedImage} title={el.file} onClose={() => setSelectedImage(null)} />}
													</div>
												</div>
											);
										})}

									<input
										id={`photos`}
										type='file'
										accept='image/png, image/jpg, image/jpeg'
										multiple
										className='form-control d-none'
										placeholder='Upload'
										name={`photos`}
										ref={inputRef}
										onChange={(e) => {
											handleFileUploadForPickDrop(e, imageFiles, setImageFiles, dropFormik);
										}}
									/>
								</div>
								<div className='mb-2'>
									<InputErrorMessage error={dropFormik?.touched?.photos && dropFormik?.errors?.photos} />
								</div>

								<div style={{ height: " 150px" }}>
									<h6 className='fw-bold'>
										Odometer reading at destination<span className='text-danger'> *</span>{" "}
									</h6>
									<input
										name='mileage'
										value={dropFormik?.values.mileage}
										onChange={(e) => dropFormik?.handleChange(e)}
										type='text'
										className='form-control py-3'
										placeholder='e.g. 10129'
									/>
									<InputErrorMessage error={dropFormik?.touched?.mileage && dropFormik?.errors?.mileage} />
								</div>
							</div>
							{loading ? 
							<button type='button' className='button text-white w-100 py-3 fw-semibold' disabled={loading} style={{ marginBottom: "5rem", opacity:0.5 }}>
							End Driving
						</button>
							:
							<button type='submit' className='button text-white w-100 py-3 fw-semibold' disabled={loading} style={{ marginBottom: "5rem" }}>
								End Driving
							</button>}
						</main>
					</form>
				)}
				
			</Layout>
		</>
	);
};

export default Index;
