import React, { useEffect } from "react";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";

const GigListMobile = ({ val }) => {
	const { el, driverId, pageId, setPageId } = val;
	const navigate = useNavigate();

	const redirect = (status) => {
		if (status === "started") {
			navigate(`/live-status/${el?.orderId}`);
		} else {
			navigate(`/gig-details/${el?.orderId}/${pageId}`);
		}
	};

	useEffect(() => {
		if (el?.rideStatus === "booked" && el?.driverId === driverId) {
			setPageId(3);
		}

		if (el?.rideStatus !== "booked") {
			setPageId(1);
		}
	}, [el]);

	return (
		<div style={{ cursor: "pointer" }} onClick={() => redirect(el?.rideStatus)} className={`text-decoration-none d-sm-none d-block `}>
			<main className={` container main-content-container mb-4`}>
				<div className='d-lg-block d-noe'>
					<div className={`row mb-2 border-bottom bg-white py-2 p-2  bg-white `}>
						<div className='px-0'>
						<div className=""><p className="fw-bold mb-1 p-12 text-dark">#{el?.orderId}</p></div>
							<div>
								<p className=' mb-1 p-12 h6'>
									<Moment format='ddd. MMM. D' date={el?.pickUpDateAndTime} />
								</p>
							</div>
							<div className='d-flex p-12 align-items-center mb-2'>
								<p className='fw-bold'>
									<Moment format='LT ' date={el?.pickUpDateAndTime} />
								</p>

								<div className='border-top  text-center px-3 light-clr' style={{ width: "98%", height: "1px", lineHeight: "0.6" }}>
									<span className='fw-bold' style={{ fontSize: "8px" }}>
										{Math.round(el?.distance) + "mi"}
									</span>
								</div>
								<p className='m-0 fw-bold'>
									<Moment format='LT ' date={el?.dropOffTime || ""} />
								</p>
							</div>
							<div className='d-flex flex-column justify-content-between'>
								<div>
									<p className='mb-0 text-fade p-12'>Pick-up Point</p>
									<p className='text-black fw-semibold p-13'>{el?.pickupAddress}</p>
								</div>
								<div className=''>
									<p className='mb-0 text-fade p-12'>Drop-off Point</p>
									<p className={`text-black fw-semibold p-13 mb-0`}>{el?.dropOffAddress}</p>
								</div>
							</div>
							<div className='row gx-0'>
								<div className='col-6'>
									<p className='text-black fw-semibold p-13'>Total</p>
								</div>
								<div className='col -6'>
									<p className={`text-end fw-semibold h6 mb-1 danger-text `}>{"$" + el?.priceForDriver}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export default GigListMobile;
