import {
	getApiWithoutToken,
	getApiWithToken,
	imgUploadApi,
	postApiWithoutToken,
	postApiWithToken,
	putApiWithoutToken,
	putApiWithToken,
} from "./api-interfaces";

export const registerApi = (payload) => {
	return postApiWithoutToken(`/api/driver/auth/register`, payload);
};

export const getAdminDetailsApi = () => {
	return getApiWithToken(`/api/common/commonAll/getAdminDetails`);
};

export const loginApi = (payload) => {
	return postApiWithToken(`/api/driver/auth/login`, payload);
};

export const confirmGigApi = (payload) => {
	return postApiWithToken(`/api/driver/request/createBooking`, payload);
};
export const pickupSubmit = (payload) => {
	return postApiWithToken(`/api/driver/request/rideArrived`, payload);
};

export const OtpSubmitApi = (payload) => {
	return postApiWithToken(`/api/driver/request/verifyOtp`, payload);
};

export const droppedSubmit = (payload) => {
	return postApiWithToken(`/api/driver/request/rideDroped`, payload);
};

export const rideStartSubmit = (payload) => {
	return postApiWithToken(`/api/driver/request/rideStarted`, payload);
};
export const updateLatLong = (payload)=>{
	return postApiWithToken(`/api/driver/request/updateLatLng`, payload);
}
export const rideEndSubmit = (payload) => {
	return postApiWithToken(`/api/driver/request/completeRequest`, payload);
};

export const fileUploadApi = (payload) => {
	return imgUploadApi(`/api/common/image/imageUpload`, payload);
};

export const multiFileUploadApi = (payload, orderId) => {
	return imgUploadApi(`/api/common/image/imageUploadByDriver?uploadType=multi&type=beforePick&orderId=${orderId}`, payload);
};
export const multiFileUploadApiDrop = (payload, orderId) => {
	return imgUploadApi(`/api/common/image/imageUploadByDriver?uploadType=multi&type=afterPick&orderId=${orderId}`, payload);
};

export const dropMultiFileUploadApi = (payload, orderId) => {
	return imgUploadApi(`/api/common/image/imageUploadByDriver?uploadType=multi&type=afterPick&orderId=${orderId}`, payload);
};

export const myProfileApi = () => {
	return getApiWithToken(`/api/driver/profile/myProfile`);
};
export const prevChatApi = (p) => {
	return getApiWithToken(`/api/common/commonAll/previousMessages?convoId=${p.convoId}&page=${p.page}&limit=${p.limit}`);
};

export const getAllGigs = (payload) => {
	return postApiWithToken(`/api/driver/request/getAllGig`, payload);
};

export const getNearGigs = (payload) => {
	return postApiWithToken(`/api/driver/request/getNearGig`, payload);
};
export const followAddApi = (payload) => {
	return postApiWithToken(`/api/driver/request/followTheGig`, payload);
};

export const getCoordsApi = (location) => {
	return getApiWithToken(`/api/common/location/getLocation?input=${location}`);
};

export const getBookingApi = (payload) => {
	return getApiWithToken(`/api/driver/request/myBooking?page=${payload.page}&limit=${payload.limit}&filter=${payload.filter}`, payload);
};

export const getGig = (id) => {
	return getApiWithToken(`/api/common/commonAll/getGigById?orderId=${id}`);
};

export const forgotPasswordApi = (payload) => {
	return postApiWithoutToken(`/api/driver/auth/forgotPassword`, payload);
};

export const updateLongLat = (payload) => {
	return postApiWithToken(`/api/driver/request/updateLatLng`, payload);
};

export const resetPasswordApi = (payload) => {
	return putApiWithoutToken(`/api/driver/auth/resetPassword`, payload);
};

export const getStatesApi = () => {
	return getApiWithoutToken(`/json/states/states.json`);
};

export const getCitiesApi = (payload) => {
	return postApiWithoutToken(`/api/driver/profile/getCities`, payload);
};

export const updateProfile = (payload) => {
	return putApiWithToken(`/api/driver/profile/updateProfile`, payload);
};

export const getZipCodeApi = () => {
	return getApiWithoutToken(`/json/zipCode/zip.json`);
};

export const getLocationInputApi = (search) => {
	return getApiWithToken(`/api/common/location/getLocation?input=${search}`);
};
