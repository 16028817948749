import React, { useContext, useEffect } from "react";
import Layout from "../../Components/Layout";
import circle from "../../Assets/images/Icon material-location-on.svg";
import moment from "moment";
import { gigContext } from ".";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ImgLoader2, Loader } from "../../Utils/Loader";
import Map from "../../Components/Map";

const Gig = () => {
	const { confirmBooking, gigState, totalTime, orderId, loader, setGigState } = useContext(gigContext);

	const data = gigState?.gigData?.data;
	const from = data?.pickupAddress;
	const to = data?.dropOffAddress;

	useEffect(() => {
		setGigState((v) => ({ ...v, modal: { isOpen: false, loading: false } }));
	}, []);

	return (
		<Layout>
			{loader ? (
				<div style={{ marginTop: "6rem", textAlign: "center" }}>
					<Loader />
				</div>
			) : (
				<>
					{gigState?.modal.isOpen && (
						<>
							<div className='newww'>
								<ToastContainer
									className='foo myToaster'
									hideProgressBar={false}
									autoClose={false}
									transition={Zoom}
									closeOnClick={false}
									closeButton={<></>}
								/>
							</div>
							<div className='overlay111' />
						</>
					)}

					<div className='main-content'>
						<div className=''>
							<div className='container px-0' style={{ height: "60vh" }}>
								<Map from={from} to={to} />
							</div>
							<div className='bg-white' style={{ borderRadius: "15px 15px 0 0" }}>
								<div className='container'>
									<div className='text-center'>
										<div className='border border-2 m-auto my-3 rounded-3' style={{ width: "50px" }}></div>
									</div>
									{/* <div className='border-bottom'>
										<p className='fw-bolder text-center p-16 p-clr'>Drives available near you</p>
									</div> */}
									<div className='d-flex align-items-center justify-content-between border-bottom py-2'>
										<div className='text-center'>
											<p className='m-0 fw-semibold p-16 p-clr'>Total Time</p>
											<p className='m-0 p-14 p-clr fw-semibold'>{`${data?.estimateEndTime || totalTime + "Mins"}`}</p>
										</div>
										<div className='text-center'>
											<p className='m-0 fw-semibold p-16 p-clr'>Distance</p>
											<p className='m-0 p-12 fw-semibold  p-clr'>{`${data?.distance || 0} Miles`}</p>
										</div>
										<div className='text-center'>
											<p className='m-0 fw-semibold p-16 p-clr'>Total Fare</p>
											<p className='m-0 p-12 fw-semibold  p-clr'>{`$ ${data?.priceForDriver || "-"}`}</p>
										</div>
									</div>
									<div className='border-bottom pt-2'>
										<h6 className='py-2 m-0 fw-semibold'>{moment(data?.pickUpDateAndTime).format("ddd. MMM. D (h:mm A)")}</h6>
										<div className='row p-2 py-3'>
											<div className='col-1 d-flex flex-column align-items-center justify-content-between' style={{ height: " 95px" }}>
												<i className='bi  bi-record-circle-fill dark-clr'></i>
												<div className='' style={{ width: " 1px", height: "55px", border: " 1px dashed gray" }}></div>
												<img src={circle} height='25px' alt='' />
											</div>
											<div className='col-10 d-flex flex-column justify-content-between'>
												<div>
													<p className='mb-0 text-fade p-12'>Pick-up Point</p>
													<p className='text-black fw-semibold p-13'>{data?.pickupAddress || "-"}</p>
												</div>
												<div className=''>
													<p className='mb-0 text-fade p-12'>Drop-off Point</p>
													<p className='text-black fw-semibold p-13'>{data?.dropOffAddress || "-"}</p>
												</div>
											</div>
										</div>
									</div>

									<div>
										<div className='d-flex align-items-center justify-content-between my-4'>
											<p className='m-0 p-16   fw-semibold p-clr'>Minimum driver class:</p>
											<div className=' p-12 light-yellow px-2  rounded-4'>Pro 1</div>
										</div>
										<h6 className='fw-bold mb-4'>{`${data?.vehicleMake} ${data?.vehicleModel}`}</h6>

										<div className='mt-3'>
											{gigState?.modal.loading ? (
												<button className='button btn-primary w-100 py-3 h4 fw-normal'>
													<ImgLoader2 />
												</button>
											) : (
												<button onClick={() => confirmBooking(orderId)} className='button btn-primary w-100 py-3 h4 fw-normal'>
													Confirm
												</button>
											)}
										</div>
										<div className="extraHeight"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Layout>
	);
};

export default Gig;
