import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../Assets/images/Group 3621.png";
import image from "../../../Assets/images/Icon ionic-md-checkmark-circle-outline.png";

function ResetThankyou() {
  return (
    <>
      <div className='container px-3'>
        <div className='py-3' style={{ height: "100px", width: "100px" }}>
          <img src={logo} alt='#' style={{ width: "100%" }} />
        </div>
        <div className='my-5 align-items-center'>
          <div
            className='check-icon m-auto rounded-circle text-center d-flex align-items-center justify-content-center'
            style={{ height: "85px", width: "85px" }}>
            <img src={image} alt='#' />
          </div>
        </div>
        <div className='text-center'>
          <h3 className='fw-bold'>Password Reset</h3>
          <p className='text-muted my-2 mb-4 p-14'>
            Your password has been successfully reset.
            <br />
            Please, click below to log in.
          </p>
        </div>
        <div className='text-center mb-4 container '>
          <Link to='/sign-in' className='btn text-white w-100 py-3'>
            Log in
          </Link>
        </div>
      </div>
    </>
  );
}

export default ResetThankyou;
