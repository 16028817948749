import React, { useState } from "react";
import { Link } from "react-router-dom";
import { InputErrorMessage } from "../../../Components/ErrorMessage";

function Form1({ children, formik1 }) {
	const [passwordType, setPasswordType] = useState("password");
	const [passwordType2, setPasswordType2] = useState("password");

	const handleChange = (e) => {
		formik1.setFieldValue(e.target.name, e.target.value);
		e.preventDefault();
	};

	const handleChange2 = (e) => {
		e.preventDefault();
		formik1.setFieldValue(e.target.name, e.target.value.replace(/[^\w\s]/gi, ""));
	};
	return (
		<>
			<form onSubmit={formik1.handleSubmit}>
				<div className='mb-4'>
					<input
						type='text'
						className='form-control'
						id='exampleInputEmail1'
						placeholder='First name'
						name='firstName'
						value={formik1.values.firstName}
						onChange={(e) => handleChange2(e)}
					/>
					<InputErrorMessage error={formik1.touched.firstName && formik1.errors.firstName} />
				</div>
				<div className='mb-4'>
					<input
						type='text'
						className='form-control '
						id='exampleInputEmail1'
						placeholder='Last name'
						name='lastName'
						value={formik1.values.lastName}
						onChange={(e) => handleChange2(e)}
					/>
					<InputErrorMessage error={formik1.touched.lastName && formik1.errors.lastName} />
				</div>
				<div className='mb-4'>
					<div className='position-relative'>
						<input
							type='text'
							className='form-control border-end-2 pe-5'
							id='exampleInputEmail1'
							placeholder='Email '
							name='email'
							value={formik1.values.email}
							onChange={(e) => handleChange(e)}
						/>
						<i className='bi bi-envelope-fill  text-muted icon-mail'></i>
					</div>
					<InputErrorMessage error={formik1.touched.email && formik1.errors.email} />
				</div>
				<div className='mb-4'>
					<div className='position-relative'>
						<input
							type={passwordType}
							className='form-control border-end-2 pe-5'
							id='exampleInputPassword1'
							placeholder='Password'
							name='password'
							value={formik1.values.password}
							onChange={(e) => handleChange(e)}
						/>

						<i
							className={`bi ${passwordType === "password" ? "bi-eye-slash-fill" : "bi-eye-fill"} icon-mail text-muted eye-pointer`}
							onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")}></i>
					</div>

					<p
						className={`mt-2 ${formik1.touched.password && formik1.errors.password ? "text-danger fw-normal" : "fw-bold"} `}
						style={{ fontSize: "14px", color: "rgba(0,0,0,0.37)  " }}>
						Password must have 8+ characters, 1 uppercase letter, 1 number and 1 special character.
						 {/* (
						{"!”#$%&'()*+,-./:;<=>?@[]^_`{|}~"} ) */}
					</p>
				</div>
				<div className='mb-4'>
					<div className='position-relative'>
						<input
							type={passwordType2}
							className='form-control pe-5'
							id='exampleInputPassword1'
							placeholder='Password again'
							name='confirmPassword'
							value={formik1.values.confirmPassword}
							onChange={(e) => handleChange(e)}
						/>
						<i
							className={`bi ${passwordType2 === "password" ? "bi-eye-slash-fill" : "bi-eye-fill"} icon-mail text-muted eye-pointer`}
							onClick={() => setPasswordType2(passwordType2 === "password" ? "text" : "password")}></i>
					</div>
					<InputErrorMessage error={formik1.touched.confirmPassword && formik1.errors.confirmPassword} />
				</div>

				<div className='text-center my-4'>{children}</div>
				<p className='text-muted text-center fs-6'>
					Already have an account?
					<Link to='/sign-in' className='fw-semibold ms-2 text-decoration-none'>
						<p className='fw-semibold fs-6 text-decoration-none d-inline-block' style={{ color: "#4488bf" }}>
							Sign in
						</p>
					</Link>
				</p>
			</form>
		</>
	);
}

export default Form1;
