import React, { useState } from "react";
import "./App.css";
import MainRouter from "../src/MainRouter/MainRouter";
import AppContext from "./app_context/index";
import { myProfileApi } from "./Api";
import { getLastElementId2 } from "./HelperFunctions";

function App() {
  const [driverProfile, setDriverProfile] = useState({ data: null, user: null, loading: false });
  const [pageId, setPageId] = useState(1);
  const orderId = getLastElementId2();

  const [gigState, setGigState] = useState({
    step: 1,
    gigData: { data: null, user: null, loading: true },
    nearbyGigs: { data: null, user: null, loading: false },
    modal: { isOpen: false, loading: false },
  });

  const logout = () => {
    localStorage.clear();
    setDriverProfile(null);
    window.location.href = "/sign-in";
  };

  const fetchDriverProfile = async () => {
    setDriverProfile({ loading: true });
    try {
      const res = await myProfileApi();
      if (res?.status === 200) {
        setDriverProfile({ data: res?.data?.data, loading: false });
      }
    } catch (err) {
      setDriverProfile({ loading: false });
      console.log(err);
    }
  };

  return (
    <AppContext.Provider
      value={{
        state: {
          driverProfile,
        },
        fetchDriverProfile,
        gigState,
        orderId,
        pageId,
        setPageId,
        setGigState,
        logout,
      }}>
      <MainRouter />
    </AppContext.Provider>
  );
}

export default App;
