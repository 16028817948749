import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { toast } from "react-hot-toast";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { OtpSubmitApi, multiFileUploadApi, rideStartSubmit, updateLatLong } from "../../Api";
import dummyImg from "../../Assets/images/Group 3706.svg";
import { InputErrorMessage } from "../../Components/ErrorMessage";
import Layout from "../../Components/Layout";
import { pickValidation } from "../../Components/Validations";
import { formatPhoneNumber, handleFileRemoveForPickDrop, handleFileUploadForPickDrop, handleMapClick } from "../../HelperFunctions";
import { GigContext } from "../../MainRouter/MainRouter";
import { Loader } from "../../Utils/Loader";
import OTP from "../../Assets/images/OTP.png";

const Index = () => {
	const { gigState } = useContext(GigContext);
	const [imageFiles, setImageFiles] = useState([]);
	const [otp, setOtp] = useState("");
	const [isVerified, setIsVerified] = useState(false);
	const [images, setImages] = useState([]);
	const pickData = gigState?.gigData?.data;
	const navigate = useNavigate();
	const [remainingTime, setRemainingTime] = useState(calculateTimeRemaining());
	const [targetTime, setTime] = useState(null);
	const canVerify = remainingTime <= 0;
	let inputRef = useRef(null);
	const [selectedImage, setSelectedImage] = useState(null);

	const handleChange = (e) => {
		setOtp(e);
	};
	async function   getLocation() {
		
		if (navigator.geolocation) {
			 navigator.geolocation.getCurrentPosition(
			  handleSuccess,
			  handleError
			);
		  } else {
			alert('Geolocation is not supported by your browser');
		  }
	  }
	  const handleSuccess = async (position) => {
		 
		 await updateLatLong({
			orderId:pickData?.orderId,
			currentLatitude:position.coords.latitude,
			currentLongitude:position.coords.longitude
		})
	  };
	
	  const handleError = (error) => {
		alert(error.message);
	  };
	
	   
	  
	const handleOtpSubmit = async (e) => {
		e.preventDefault();
		try {
			const res = await OtpSubmitApi({ orderId: pickData?.orderId, otp });
			if (res.status === 200) {
				setIsVerified(true);
			}
		} catch (err) {
			setIsVerified(false);
			toast.error(err.response.data.message, { id: "etrr01" });
		}
	};

	let pickupFormik = useFormik({
		initialValues: {
			photos: "",
			mileage: "",
			orderId: "",
			type: "beforePick",
		},
		validationSchema: pickValidation,
		onSubmit: async (values) => {
 
			await getLocation();
		 
			const omitted = _.omit(values, ["photos", "type"]);
			const formdata = new FormData();
			const loadingToast = toast.loading("Loading...", { id: "122" });
			document.getElementById("startBtn").disabled = true;
			document.getElementById("startBtn").setAttribute("class", " button text-white w-100 py-3 fw-semibold  bg-secondary");

			imageFiles.forEach((file) => formdata.append("file", file));

			try {
				const fileRes = await multiFileUploadApi(formdata, pickData?.orderId);
				if (fileRes.status === 200) {
					pickupFormik?.setFieldValue("photos", fileRes?.data?.filePath);
					toast.dismiss(loadingToast);
					const fileToast = toast.success(fileRes?.data?.message, { id: "1221" });
					const startRes = await rideStartSubmit({...omitted,time:moment().format('ddd MMM D, (hh:mm A)')});
					if (startRes.status === 200) {
						toast.dismiss(fileToast);
						toast.success(startRes?.data, { id: "1222" });
						navigate(`/live-status/${pickData?.orderId}`, { replace: true });
						toast.dismiss(fileToast);
						toast.success(startRes?.data, { id: "1222" });
					}
				}
			} catch (err) {
				document.getElementById("startBtn").disabled = false;
				document.getElementById("startBtn").setAttribute("class", "button text-white w-100 py-3 fw-semibold");
				toast.dismiss(loadingToast);
				toast.error(err.response.data.message, { id: "etrr01" });
			}
		},
	});

	useEffect(() => {
		let imgfnc = [],
			fileReaders = [];
		let isCancel = false;

		imageFiles?.forEach((file) => {
			const fileReader = new FileReader();

			fileReaders.push(fileReader);
			fileReader.onload = (e) => {
				let { result } = e.target;
				if (result) {
					imgfnc = [...imgfnc, { result, file: file?.name }];
				}

				if (imgfnc.length === imageFiles.length && !isCancel) {
					setImages(imgfnc);
				}
			};
			fileReader.readAsDataURL(file);
		});
		if (pickupFormik.values.photos === "") {
			setImages([]);
		}

		return () => {
			isCancel = true;
			fileReaders.forEach((fileReader) => {
				if (fileReader.readyState === 1) {
					fileReader.abort();
				}
			});
		};
	}, [imageFiles]);

	useEffect(() => {
		if (pickData?.verifySecurityCode) {
			setIsVerified(true);
		}
		if (pickData?.orderId) {
			pickupFormik.setFieldValue("orderId", pickData?.orderId);
		}
	}, [pickData]);

	function calculateTimeRemaining(targetTime) {
		let current = moment(new Date());
		let later = moment(targetTime);
		let result = later.diff(current, "seconds");

		return result;
	}

	useEffect(() => {
		if (pickData?.pickUpDateAndTime && !targetTime) {
			setTime(new Date(pickData?.pickUpDateAndTime));
		}
	}, [pickData?.pickUpDateAndTime]);

	useEffect(() => {
		const timer = setInterval(() => {
			setRemainingTime(calculateTimeRemaining(targetTime));
		}, 1000);

		return () => clearInterval(timer);
	}, [targetTime]);

	useEffect(() => {
		const inputs = document.querySelectorAll(".OtpClose input");
		inputs.forEach((input) => {
			input.disabled = canVerify ? false : true;
		});
	}, [remainingTime]);

	const diff = moment.duration(moment().diff(moment(targetTime))).format("dd [Days,] hh [Hours,] mm [Minutes,] ss [Seconds]");

	const openImagePreview = (image) => {
		setSelectedImage(image);
	};

	return (
		<Layout>
			{!pickData ? (
				<div className='container text-center pt-5'>
					<Loader />
				</div>
			) : isVerified ? (
				//Start driving Form
				<form onSubmit={pickupFormik?.handleSubmit}>
					<main className='container px-3'>
						<div className='light-yellow-bg py-3 px-2 mb-2 d-flex justify-content-center align-items-center'>
							<p className='m-0 h2 fw-bold '>{pickData?.securityCode}</p>
						</div>
						<div className='row mb-3' style={{ marginTop: "2rem" }}>
							<div className='col-10'>
								<h6 className='fw-bold'>Pickup address</h6>

								<p className='p-13 fw-semibold text-muted'>{pickData?.pickupAddress}</p>
							</div>

							<div className='col-2 d-flex justify-content-end align-items-center'>
								<span style={{ cursor: "pointer" }} onClick={() => handleMapClick(pickData?.pickupAddress)}>
									<img src={dummyImg} alt='' />
								</span>
							</div>
						</div>
						<div className='row mb-5'>
							<div className='col-10'>
								<h6 className='fw-bold'>Delivery address</h6>
								<p className='p-13 fw-semibold text-muted'>{pickData?.dropOffAddress}</p>
							</div>
							<div className='col-2 d-flex justify-content-end align-items-center'>
								<span style={{ cursor: "pointer" }} onClick={() => handleMapClick(pickData?.dropOffAddress)}>
									<img src={dummyImg} alt='' />
								</span>
							</div>
						</div>
						{formatPhoneNumber(pickData)}
						<div>
							<div className='d-flex justify-content-between align-items-center mb-3 '>
								<p className='text-14 p-16 my-auto fw-semibold text-muted'>
									Photos before pickup<span className='text-danger'> *</span>
								</p>
								<label htmlFor={`photos`}>
									<div id='addBtn1' className='changeFileBtn btn text-white py-1 h-100 bg-none rounded-2'>
										Add photos
									</div>
								</label>
							</div>

							<div className='d-flex mx-2 flex-wrap mb-3' style={{ height: "200px", overflowY: "auto" }}>
								{images.length >= 1 &&
									images?.map((el, i) => {
										return (
											<div key={`pick${i}`} className='mb-2 me-2 position-relative imageSubmit-container'>
												<span
													style={{ cursor: "pointer", right: "0.5rem" }}
													className='position-absolute'
													onClick={() => handleFileRemoveForPickDrop(imageFiles, setImageFiles, i, pickupFormik, inputRef)}>
													<i className='bi bi-x-circle me-2 crossCircle'></i>
												</span>

												<img
													src={el.result}
													alt=''
													className='img-fluid rounded'
													style={{ width: "100%", height: "100%", objectFit: "cover", border: "1px solid lightGrey", cursor: "pointer" }}
													onClick={() => openImagePreview(el.result)}
												/>
												<div className='modalPrev'>
													{selectedImage && (
														<Lightbox
															zoomStep={0.5}
															doubleClickZoom={5}
															allowRotate={false}
															image={selectedImage}
															title={el.file}
															onClose={() => setSelectedImage(null)}
														/>
													)}
												</div>
											</div>
										);
									})}

								<input
									id={`photos`}
									type='file'
									// accept='image/*'
									multiple
									className='form-control d-none'
									placeholder='Upload'
									name={`photos`}
									ref={inputRef}
									onChange={(e) => {
										handleFileUploadForPickDrop(e, imageFiles, setImageFiles, pickupFormik);
									}}
								/>
							</div>
							<div className='mb-2'>
								<InputErrorMessage error={pickupFormik?.touched?.photos && pickupFormik?.errors?.photos} />
							</div>

							<div className='mb-4'>
								<h6 className='fw-bold'>
									Odometer reading at origin<span className='text-danger'> *</span>
								</h6>
								<input
									name='mileage'
									value={pickupFormik?.values.mileage}
									onChange={(e) => pickupFormik?.handleChange(e)}
									type='text'
									className='form-control py-3'
									placeholder='e.g. 10129'
								/>
								<InputErrorMessage error={pickupFormik?.touched?.mileage && pickupFormik?.errors?.mileage} />
							</div>
						</div>

						<button type='submit' id='startBtn' className='button text-white w-100 py-3 fw-semibold mb-3' >
							Start Driving 
						</button>
						<div className="extraHeight"></div>
					</main>
				</form>
			) : (
				//OTP Form
				<div className="d-flex align-items-center justify-content-center">
				<div className="">
					<div className='text-center my-3 mb-4'>
						<img src={OTP} className='img-fluid' alt='#' />
					</div>
					<div className=' p-3 text-center otpInputBox mt-5'>
						<form className="mb-3">
							<div className="mb-3">
								{canVerify ? (
									<h4 className='fw-semibold text-black'>Please enter the last four digit of VIN number to start your gig.</h4>
								) : (
									
									<div className=' timerr'>
										<h4 className='fw-semibold text-black mb-3'>You can start ride after.</h4>
										{diff.replace("-", "")}
										<h6 className='text-black my-3'>Please enter the last four digit of VIN number to start your gig.</h6>
										{/* <Moment format='dd:hh:mm:ss' date={targetTime} durationFromNow /> */}
									</div>
									
								)}
							</div>
							<div className=' mb-4'>
								<div className='OtpClose'>
									<OtpInput
										inputStyle={{
											marginLeft: "0",
											width: "2.375rem",
											height: "2.375rem",
											borderRadius: "0.8rem",
											textAlign: "center",
											border: "1px solid rgba(0,0,0,0.3)",
											display: "flex",
											justifyContent: "space-around",
										}}
										className={` text-center fs-3 m-auto w-100 px-0 my-3 otpInputt`}
										errorStyle={{ border: "solid 1px red" }}
										inputType={"text"}
										value={otp}
										onChange={(e) => handleChange(e)}
										numInputs={4}
										separator={<span> </span>}
										renderSeparator={<span> &nbsp;&nbsp;</span>}
										renderInput={(props) => <input {...props} />}
									/>
									<div className='pt-3'>
										<kbd style={{ fontSize: "20px" }} className='py-1 px-3  fw-normal'>
											Code: {pickData?.securityCode}
										</kbd>
									</div>
								</div>
							</div>

							<button
								type='submit'
								className={`d-block btn button btn-primary ${!canVerify && "bg-secondary"} py-2 px-5 h4 fw-normal`}
								style={{ margin: "0 auto" }}
								onClick={(e) => (canVerify ? handleOtpSubmit(e) : e.preventDefault())}>
								Verify
							</button>
						</form>
						
					</div>
					<div style={{height:"200px"}}></div>
				</div>
				</div>
			)}
		</Layout>
	);
};

export default Index;
