import React, { useContext } from "react";
import Layout from "../../Components/Layout";
import circle from "../../Assets/images/Icon material-location-on.svg";
import { Loader } from "../../Utils/Loader";
import { gigContext } from ".";
import Map from "../../Components/Map";
import GigListMobile from "../../Components/GigList/Mobile";
import GigListDesktop from "../../Components/GigList/Desktop";
import StarRatings from "react-star-ratings";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formatPhoneNumber3 } from "../../HelperFunctions";

const GigBook = () => {
  const {
    gigState,
    totalTime,
    isZeroMins,
    orderId,
    pageId,
    setPageId,
    loader,
  } = useContext(gigContext);
  const navigate = useNavigate();

  const data = gigState?.gigData?.data;
  const from = data?.pickupAddress;
  const to = data?.dropOffAddress;

  const nearbyGigsData = () => {
    const gigs = gigState?.nearbyGigs?.data;
    const gigLength = gigs?.length >= 1;

    const filteredGigs = gigs?.filter((el) => {
      return el?.id !== data?.id;
    });

    const allGigs = gigs
      ?.filter((el) => {
        return el?.id !== data?.id;
      })
      ?.map((el, i) => {
        const isDelOrCancl = Boolean(
          el.status === "completed" || el.status === "cancelled"
        );
        const isOnlyCncl = Boolean(el.status === "cancelled");
        const isOnlyDel = Boolean(el.status === "completed");
        const props = {
          isDelOrCancl,
          isOnlyCncl,
          isOnlyDel,
          el,
          i,
          pageId,
          setPageId,
        };
        return (
          <div key={`gigBook_${i}`}>
            <GigListDesktop val={props} />
            <GigListMobile val={props} />
          </div>
        );
      });

    if (gigState?.nearbyGigs.loading) {
      return (
        <div className=" text-center py-2">
          <Loader />
        </div>
      );
    } else {
      if (gigLength) {
        if (filteredGigs?.length >= 1 && allGigs?.length === 0) {
          return (
            <div className="text-center py-2 text-muted">
              <i>No data found</i>
            </div>
          );
        } else if (filteredGigs?.length === 0 || allGigs?.length === 0) {
          return (
            <div className="text-center py-2 text-muted">
              <i>No data found</i>
            </div>
          );
        } else {
          return allGigs;
        }
      } else {
        return (
          <div className=" text-center py-2 text-muted">
            <i>No data found</i>
          </div>
        );
      }
    }
  };

  return (
    <Layout>
      {loader ? (
        <div style={{ marginTop: "6rem", textAlign: "center" }}>
          <Loader />
        </div>
      ) : (
        <div className="main-content ">


          {data?.status =="completed"
          ?
          <>
             <div
            className="card  my-3"
            style={{ border: "1px solid rgb(233, 233, 233)", }}
          >
            <div className="border-top" />
            <div className="card-body px-0 pb-0">
            <div className='light-yellow-bg py-2 px-2 mb-2 d-flex justify-content-center align-items-center'>
                        <p className='m-0 h2 fw-bold text-dark'>{data?.orderId || "-"}</p>
                      </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Customer name</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
                 {data?.customerFullName || "-"}
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Phone number</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
                 {formatPhoneNumber3(data) || "-"}
                </div>
              </div>

              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Customer email</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
                 {data?.customerEmail || "-"}
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Other information (Cust.)</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
			{data?.customerOtherInfos || "-"}
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">License plate</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
                 {data?.vehiclePlateNumber || "-"}
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Four last digits of the VIN</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
                 {data?.vinNumber || "-"}
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Make and Model</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
                 {data?.vehicleMake} {data?.vehicleModel}
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Transmission</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
                 {data?.transmission || "-"} 
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Vehicle Other information </div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
			{data?.vehicleOtherInformation || "-"}
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Pickup address</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
                 {data?.pickupAddress || "-"}
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Dropoff address</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
                {data?.dropOffAddress || "-"}
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Distance</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
                 {data?.distance || "-"} miles
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Estimated duration</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold ">
                 {data?.estimateEndTime || "-"}
                </div>
              </div>
              <div className='row mb-2 px-3'> 
                      <div className='col-5 text-fade p-11'>Earliest pickup time</div>
                      <div className='col-1 text-fade'>:</div>
                      
                      <div className='col-5 p-13 fw-semibold'>
                        
                     {moment(data?.pickUpDateAndTime).format("ddd. MMM. D, (h:mm A)")}
                      </div>
                    </div>
                    <div className='row mb-2 px-3'>
                      <div className='col-5 text-fade p-11'>Must arrival latest</div>
                      <div className='col-1 text-fade'>:</div>
                      <div className='col-5 p-14 fw-semibold'>{isZeroMins(data?.dropOffTime, "drop")}</div>
                    </div>
              

              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">
                  Mileage before pickup
                </div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold">{(data?.mileageBeforePick || "-")}</div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">
                  Mileage after delivery
                </div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-13 fw-semibold">{(data?.mileageAfterDeliver || "-")}</div>
              </div>
              <div class="row mb-2 px-3">
                <div class="col-5 text-fade p-11">Start time</div>
                <div class="col-1 text-fade">:</div>
                <div class="col-5 p-13 fw-semibold">{data?.driverOrderInfo?.startTime || "-"}</div>
              </div>
			  <div class="row mb-2 px-3">
                <div class="col-5 text-fade p-11">End time</div>
                <div class="col-1 text-fade">:</div>
                <div class="col-5 p-13 fw-semibold">{data?.driverOrderInfo?.endTime || "-"}</div>
              </div>
			
			
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">Images before pickUp</div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-14 fw-semibold">
                  <div className="d-flex flex-wrap justify-content-between">
                  {Boolean(data?.photosBeforePick?.length)&& data.photosBeforePick.map((k,i)=>( <a
                      href={k}
                      target="_blank1"
                    >
                      <div className="mb-2" style={{ width: 80, height: 40 }}>
                        <img
                          src={k}
                          alt="carImage0"
                          className="border w-100 h-100"
                        />
                      </div>
                    </a>))}
                   </div>
                </div>
              </div>
              <div className="row mb-2 px-3">
                <div className="col-5 text-fade p-11">
                  Images after dropoff
                </div>
                <div className="col-1 text-fade">:</div>
                <div className="col-5 p-14 fw-semibold">
                  <div className="d-flex flex-wrap justify-content-between">
                  {Boolean(data?.photosAfterDeliver?.length)&& data.photosAfterDeliver.map((k,i)=>( <a
                      href={k}
                      target="_blank1"
                    >
                      <div className="mb-2" style={{ width: 80, height: 40 }}>
                        <img
                          src={k}
                          alt="carImage0"
                          className="border w-100 h-100"
                        />
                      </div>
                    </a>))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ratingBox text-center pt-5 mb-5 ">
          <StarRatings
            rating={parseFloat(data?.rating)}
            starDimension="34px"
            starSpacing="5px"
            starRatedColor="rgb(255, 188, 11)"
          />
        </div>
        <div style={{ height: "4rem" }}></div>
        </>
          :
          <>
		<h4 class="fw-semibold my-3">Gig Details</h4>

          <div className=" ">
            <div className="container px-0" style={{ height: "60vh" }}>
              <Map from={from} to={to} />
            </div>
            <div className="bg-white" style={{ borderRadius: " 5px 5px 0 0" }}>
              <div className="container ">
                <div className="text-center">
                  <div
                    className="border border-2 m-auto my-3 rounded-3"
                    style={{ width: "50px" }}
                  ></div>
                </div>
                <div className="d-flex align-items-center justify-content-between border-bottom  py-2">
                  <div className="text-center">
                    <p className="m-0 fw-semibold p-16 p-clr">Total Time</p>
                    <p className="m-0 p-14 p-clr fw-semibold">{`${
                      data?.estimateEndTime || totalTime + "Mins"
                    }`}</p>
                  </div>
                  <div className="text-center ">
                    <p className="m-0 fw-semibold p-16 p-clr">Distance</p>
                    <p className="m-0 p-14 p-clr fw-semibold">{`${
                      data?.distance || 0
                    } Miles`}</p>
                  </div>
                  <div className="text-center  ">
                    <p className="m-0 fw-semibold p-16 p-clr">Total Fare</p>
                    <p className="m-0 p-14 p-clr fw-semibold">{`$ ${
                      data?.priceForDriver || 0
                    }`}</p>
                  </div>
                </div>
                <div className="border-bottom pt-2">
                  <h6 className="py-2 m-0 fw-semibold">
                    {isZeroMins(data?.pickUpDateAndTime)}
                  </h6>
                  <div className="row p-2 py-3 ">
                    <div
                      className="col-1 d-flex flex-column align-items-center justify-content-between "
                      style={{ height: " 95px" }}
                    >
                      <i className="bi  bi-record-circle-fill dark-clr"></i>
                      <div
                        className=""
                        style={{
                          width: " 1px",
                          height: "55px",
                          border: " 1px dashed gray",
                        }}
                      ></div>
                      <img src={circle} height="25px" alt="" />
                    </div>
                    <div className="col-10 d-flex flex-column justify-content-between">
                      <div>
                        <p className="mb-0 text-fade p-12">Pick-up Point</p>
                        <p className="text-black fw-semibold p-13">
                          {data?.pickupAddress || "-"}
                        </p>
                      </div>
                      <div className="">
                        <p className="mb-0 text-fade p-12">Drop-off Point</p>
                        <p className="text-black fw-semibold p-13">
                          {data?.dropOffAddress || "-"}
                        </p>
                      </div>
                      {data?.status === "completed" && (
                        <div className="d-flex justify-content-between">
                          <div className="">
                            <p className="mb-0 text-fade p-12">
                              Mileage before pickup
                            </p>
                            <p className="text-black fw-semibold p-13">
                              {data?.mileageBeforePick || "-"}
                            </p>
                          </div>
                          <div className="">
                            <p className="mb-0 text-fade p-12">
                              Mileage after deliver
                            </p>
                            <p className="text-black fw-semibold p-13">
                              {data?.mileageAfterPick || "-"}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <p className="m-0 p-14 fw-semibold p-clr fw-semibold">
                      Minimum driver class:
                    </p>
                    <div className="p-12 light-yellow px-2 rounded-4">
                      Pro 1
                    </div>
                  </div>
                  <h6 className="fw-bold mb-4">{`${data?.vehicleMake} ${data?.vehicleModel}`}</h6>
                  <div className="light-blue-bg rounded-2 p-2 px-3">
                    <div className="border-bottom">
                      <div className="d-flex justify-content-between">
                        <p className=" mb-2 p-14 h6 p-clr">Basic payment:</p>
                        <p className=" fw-semibold h6 mb-1  p-clr">{`$ ${
                          data?.priceForDriver || "-"
                        }`}</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between fw-bolder py-1">
                      <p className=" mb-1 p-14 p-clr">Total Fare:</p>
                      <p className=" fw-bolder h6 mb-1 p-clr">{`$ ${
                        data?.priceForDriver || "-"
                      }`}</p>
                    </div>
                  </div>
				  
                  <div className="mt-3">
                    {data?.status === "completed" ? (
                      <>
                        
                      </>
                    ) : data?.status === "expired" ? (
                      <div className="text-danger text-center h1 text-uppercase">
                        Expired
                        <div style={{ height: "6rem" }}></div>
                      </div>
                    ) : (
                      <>
                        <button
                          className="button btn-primary w-100 py-3 h4 fw-normal"
                          onClick={() => {
                            setPageId((v) => v + 1);
                            navigate(`/gig-details/${orderId}/${pageId + 1}`);
                          }}
                        >
                          Book this gig
                        </button>

                        <div className="mb-3 container">
                          <h5 className="fw-semibold my-3">
                            Other gigs from nearby
                          </h5>
                          <div className="row gx-0 d-flex justify-content-around align-items-center mb-2">
                            <div className="col-12 rounded px-3 py-2">
                              
                              {nearbyGigsData()}
                            </div>
                          </div>
                        </div>
                        <div className="extraHeight"></div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>}
        </div>
      )}
    </Layout>
  );
};

export default GigBook;
