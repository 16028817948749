import React, { useContext, useEffect, useState } from "react";
import { followAddApi, getAllGigs, myProfileApi } from "../../Api";
import Layout from "../../Components/Layout";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import { Loader } from "../../Utils/Loader";
import Pagination from "../../Components/Pagination";
import SearchGigs from "./SearchGigs";
import GigListMobile from "../../Components/GigList/Mobile";
import GigListDesktop from "../../Components/GigList/Desktop";
import { toast } from "react-hot-toast";
import { GigContext } from "../../MainRouter/MainRouter";

const Dashboard = () => {
	const [dashState, setDashState] = useState({
		driverProfile: { loading: false, data: null, user: null },
		allGigsData: { loading: false, totalGigs: 0, data: "", totalPageCount: "" },
		allGigsCount: { loading: false, data: null, user: null },
		searchFilter: {
			from: "",
			to: "",
			pickUpDate: "",
			pickUpTime: "",
			payout: "",
			page: 1,
			limit: 10,
			filter: "active",
		},
	});

	const { pageId, setPageId } = useContext(GigContext);
	const filterOptions = [
		{ value: "active", label: "Active" },
		{ value: "booked", label: "Booked" },
		{ value: "", label: "Listed" },
		{ value: "completed", label: "Delivered" },
		{ value: "cancelled", label: "Cancelled" },
	];

	const formik = useFormik({
		initialValues: {
			from: "",
			to: "",
			pickUpDate: "",
			pickUpTime: "",
			payout: "",
			page: 1,
			limit: 10,
			filter: dashState?.searchFilter?.filter,
		},
		validationSchema: "",
		onSubmit: async (values) => {
			setDashState((v) => ({ ...v, allGigsData: { loading: true } }));
			try {
				const payload = { ...values };

				payload.pickUpDate = values.pickUpDate === "" ? "" : moment(values.pickUpDate).format();
				payload.pickUptime = values.pickUptime === "" ? "" : moment(values.pickUptime).format();
				const res = await getAllGigs(payload);

				if (res.status === 200) {
					setDashState((v) => ({
						...v,
						searchFilter: {
							from: values.from,
							to: values.to,
							pickUpDate: values.pickUpDate,
							pickUpTime: values.pickUpTime,
							payout: values.payout,
							page: values.page,
							limit: values.limit,
							filter: dashState?.searchFilter?.filter,
						},
						driverProfile: { loading: false },
						allGigsData: {
							loading: false,
							totalGigs: res?.data?.totalGigs,
							data: res?.data?.AllGigs,
							totalPageCount: res.data.pages,
						},
					}));
				}
			} catch (err) {
				setDashState((v) => ({ ...v, driverProfile: { loading: false } }));
			}
		},
	});

	const handleChange = (name, value) => {
		setDashState((v) => ({
			...v,
			searchFilter: { ...dashState?.searchFilter, [name]: value, filter: dashState?.searchFilter?.filter },
		}));
	};

	const handlePageClick = ({ selected }) => {
		handleChange("page", selected + 1);
	};

	const fetchDriverProfile = async () => {
		setDashState((v) => ({ ...v, driverProfile: { loading: true } }));
		try {
			const res = await myProfileApi();
			if (res.status === 200) {
				setDashState((v) => ({ ...v, driverProfile: { data: res?.data?.data, loading: false } }));
			}
		} catch (err) {
			setDashState((v) => ({ ...v, driverProfile: { loading: false } }));
		}
	};
	const fetchAllGigs = async (payload) => {
		setDashState((v) => ({ ...v, driverProfile: { loading: true }, allGigsData: { loading: true, totalGigs: 0 } }));

		try {
			const res = await getAllGigs(payload);
			if (res.status === 200) {
				setDashState((v) => ({
					...v,
					driverProfile: { loading: false },
					allGigsData: {
						totalGigs: res?.data?.totalGigs,
						data: res?.data?.AllGigs,
						loading: false,
						totalPageCount: res.data.pages,
					},
				}));
			}
		} catch (err) {
			setDashState((v) => ({ ...v, driverProfile: { loading: false }, allGigsData: { loading: false, totalGigs: 0 } }));
		}
	};

	const handleFollow = async (values) => {
		try {
			if (values.from === "" || values.to === "") {
				toast("Address field can't be empty", { icon: "⚠️", id: "warnFollow" });
			} else {
				const res = await followAddApi(values);
				if (res.status === 200) {
					toast.success(res.data, { id: "0124df" });
				}
			}
		} catch (err) {
			return err;
		}
	};

	useEffect(() => {
		fetchAllGigs(dashState?.searchFilter);
	}, [dashState?.searchFilter?.page]);

	useEffect(() => {
		fetchDriverProfile();
	}, [dashState?.searchFilter]);

	return (
		<div>
			<Layout>
				<div className='main-content'>
					<div className='mt-2 pt-2' style={{ marginBottom: "2rem" }}>
						<SearchGigs handleFollow={handleFollow} formik={formik} dashState={dashState} setDashState={setDashState} />
						<div className='text-center py-2 my-3 light-clr fw-semibold' style={{ backgroundColor: "#F1F6FF" }}>
							{`${dashState.allGigsData.totalGigs || 0} Gigs available`}
						</div>
						<div className='container'>
							{dashState?.allGigsData.loading ? (
								<div className=' text-center py-2'>
									<Loader />
								</div>
							) : dashState?.allGigsData?.data?.length >= 1 ? (
								dashState?.allGigsData?.data?.map((el, i) => {
									const props = {
										el,
										pageId,
										setPageId,
										i,
										driverId: dashState?.driverProfile?.data?.id,
									};
									return (
										<div key={`dash${i}`} className={`row`}>
											<GigListDesktop val={props} />
											<GigListMobile val={props} />
										</div>
									);
								})
							) : (
								<div className='text-center py-2 text-muted'>
									<i>No data found</i>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className=' mb-5'>
					<Pagination pageCount={dashState?.allGigsData?.totalPageCount} onPageChange={handlePageClick} itemsPerPage={10} />
				</div>
				<div className="extraHeight"></div>
			</Layout>
		</div>
	);
};

export default Dashboard;
