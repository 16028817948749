import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../Assets/images/Group 3621.png";
import pic from "../../../Assets/images/Icon feather-key.png";
import { ForgotPasswordForm } from "../../../Components/Forms";

function ForgetPassword() {
	const forgotPasswordFormik = ForgotPasswordForm();

	return (
		<div className='container px-3'>
			<div className='py-3' style={{ height: "100px", width: "100px" }}>
				<img src={logo} alt='#' style={{ width: "100%" }} />
			</div>

			<div className='my-5 align-items-center '>
				<div className='icon m-auto rounded-circle text-center d-flex align-items-center justify-content-center' style={{ height: "85px", width: "85px" }}>
					<img src={pic} alt='#' />
				</div>
			</div>
			<div className='text-center'>
				<h1 className='fw-bold p-25'>Forgot password?</h1>
				<p className='text-muted mb-4 h6'>No worries, we'll send you reset instructions.</p>
			</div>

			<form onSubmit={forgotPasswordFormik.handleSubmit}>
				<div className='my-5'>
					<label htmlFor='exampleInputEmail1' className='form-label fw-semibold mb-1'>
						Email
					</label>
					<div className='position-relative'>
						<input
							type='email'
							className='form-control border-end-2 pe-5'
							id='exampleInputEmail1'
							placeholder='Enter your email '
							name='email'
							value={forgotPasswordFormik.values.email}
							onChange={forgotPasswordFormik.handleChange}
						/>
						<i className='bi bi-envelope icon-mail text-muted'></i>
					</div>
					{Boolean(forgotPasswordFormik.touched.email && forgotPasswordFormik.errors.email) && (
						<p className='text-danger mt-2'>{forgotPasswordFormik.errors.email}</p>
					)}
				</div>
				<div className='text-center'>
					<button type='submit' className='btn py-3 text-white w-100 mb-3'>
						Next
					</button>
					<Link to='/sign-in' className='text-decoration-none text-muted'>
						<i className='bi bi-arrow-left me-2'></i>Back to log in
					</Link>
				</div>
			</form>
		</div>
	);
}

export default ForgetPassword;
