import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/images/Group 3621.png";
import bgImg from "../../Assets/images/image-7.jpeg";

const VerificationPending = () => {
  return (
    <div
      style={{
        background: "#4488bf",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <div
        className='container'
        style={{
          background: "#f7f7f7",
          maxWidth: "70%",
          borderRadius: "10px",
          boxShadow: " 0px 17px 25px -10px rgba(0,0,0,0.4)",
        }}>
        <div className='row'>
          <div className='col-12 p-4'>
            <div className='logoImgg text-center'>
              <img src={logo} alt='' />
            </div>
            <div className='bgImg mb-2'>
              <img src={bgImg} alt='' style={{ width: "50%", margin: "0 auto", display: "flex" }} />
            </div>
            <div className='text'>
              <h1 className='text-center fw-bold' style={{ color: "#28599B" }}>
                We'll get back to you
              </h1>
              <h6 className='text-center fw-light'>Once your profile gets verified by Admin.</h6>
              <div className='text-center '>
                <Link to='/sign-in' className='btn text-white'>
                  Go to sign in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationPending;
