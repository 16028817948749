import React, { useEffect, useRef, useState } from "react";
import Home from "../../Assets/images/Mask Group 2259.png";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { API_BASE_URL } from "../../Utils/BaseUrl";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { fileUploadApi, getAdminDetailsApi, myProfileApi, prevChatApi } from "../../Api";
import * as yup from "yup";
import { Loader } from "../../Utils/Loader";
import { assignBucketUrl } from "../../HelperFunctions";
import Layout from "../../Components/Layout";

const Chat = () => {
	const navigate = useNavigate();
	const [socket, setSocket] = useState(io(API_BASE_URL, { transports: ["websocket"], query: { convoId: "" } }));
	const [attaches2, setAttaches2] = useState({ data: null, loading: false });
	const [admin, setAdmin] = useState("");
	const [adminLoading, setAdminLoading] = useState(true);
	const [driver, setDriver] = useState("");
	const scrollRef = useRef(null);
	const [chat, setChat] = useState([]);
    const [chatLoading, setChatLoading] = useState(true);

	
	const msgValidation = yup.object({
		message: yup.string().required(),
	});

	const formik = useFormik({
		initialValues: {
			message: "",
			senderId: "",
			recieverId: "",
			convoId: "",
			attachment: {},
			senderName: "",
			senderProfile: "",
			chatType: "driver",
		},
		validationSchema: msgValidation,
		onSubmit: async (values) => {
			values.message = values?.message?.trim();

			if (!values?.message) {
				if (attaches2?.data) {
					socket.emit("send message", values);

					socket.on("message", (values) => {
						setChat((c)=>[...c, values]);
						console.log('call 1')
					});

					formik.setFieldValue("attachment", "");
				}
			} else if (values?.message) {
				socket.emit("send message", values);
				socket.on("message", (message) => {
					setChat((c) => {
						if(c?.find(l=>l?.id == message?.id)){
						  return c
						}
						return [...c, message]
					  });
					console.log('call 2')

				});
				formik.setFieldValue("message", "");
			}
			setAttaches2({ data: null });
		},
	});

	const fetchDriver = async () => {
		setDriver({ loading: true });
		try {
			const res = await myProfileApi();
			if (res.status === 200) {
				setDriver({ loading: false });
				setDriver({ data: res?.data?.data, loading: false });
			}
		} catch (err) {
			setDriver({ loading: false });
		}
	};

	const getAdminDetails = async () => {
		try {
			const res = await getAdminDetailsApi();
			if (res.status === 200) {
				setAdmin(res?.data[0]);
				setAdminLoading(false)
			}
		} catch (err) {
			setAdminLoading(false)
			return err;
		}
	};

	useEffect(() => {
		if (admin !== "" && driver !== "") {
			formik.setFieldValue("convoId", `${driver?.data?.uid}_${admin.id}`);
			formik.setFieldValue("senderId", `${driver?.data?.uid}`);
			formik.setFieldValue("recieverId", `${admin.id}`);
			formik.setFieldValue("senderName", `${driver?.data?.firstName} ${driver?.data?.lastName}`);
			formik.setFieldValue("senderProfile", driver?.data?.profilePicture);
			setSocket(io(API_BASE_URL, { transports: ["websocket"], query: { convoId: `${driver?.data?.uid}_${admin.id}` } }));
		}
	}, [driver, admin]);

	useEffect(() => {
		getAdminDetails();
		fetchDriver();

		return () => {
			socket.off("message");
		};
	}, []);
	const fetchPreviousMessages = async (p) => {
        try {
            const res = await prevChatApi(p)
            setChat(res?.data) 
            setChatLoading(false)
        } catch (error) {
            return error
        }
    }
	
	useEffect(() => { 

		if (socket?._opts?.query?.convoId && !socket?._opts?.query?.convoId?.includes("undefined")) {
			fetchPreviousMessages({convoId : socket?._opts?.query?.convoId, page : 1, limit : 50})
		}
	}, [socket?._opts?.query?.convoId]);


	function scrollToBottom() {
		scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
	}

	useEffect(() => {
		if(scrollRef?.current){
		scrollToBottom();
		}
	}, [chat]);

	return (
		<>
			<Layout>
				<main className='' style={{ backgroundColor: "#fafafa" }}>
				{!adminLoading ? <div className=' container-lg '>
						<div className='gap-4 chat-row p-2  border-bottom'>
							<div className='list-group-item d-flex justify-content-between align-items-center sky-blue-bg py-2 px-2'>
								<div className='d-flex'>
									<div className='me-4'>
										<div className='avatar position-absolute' style={{ width: 40, height: 40 }}>
											<img
												alt='...'
												className='rounded-circle'
												style={{ height: "100%", width: "100%", objectFit: "cover" }}
												src={assignBucketUrl(admin?.profilePicture)}
												onError={(event) => {
													event.target.src = "https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg";
													event.onerror = null;
												}}
											/>
										</div>
										{/* <div
											className='border rounded-circle position-relative'
											style={{
												width: 10,
												height: 10,
												right: "-32px",
												bottom: "-25px",
												backgroundColor: "#03ca4c",
											}}
										/> */}
									</div>
									<div className='flex-fill ms-3 '>
										<div className='d-flex justify-content-between align-items-center'>
											<h6 href='#' className='d-block fw-bold mb-1 text-dark'>
												{admin !== "" ? admin.firstName + " " + admin.lastName : "Admin"}
											</h6>
										</div> 
										<p>101Drivers chat</p>
									</div>
								</div>
								<div style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
									<i className='bi bi-x-lg h6 px-3 ' />
								</div>
							</div>
						</div>
						<div className='container mt-3' style={{ height: "calc(100vh - 280px)", overflowY: "scroll" }}>
							

							<div ref={scrollRef} className='pb-1'>
								{chatLoading
								?
								<div className='text-center'>
										<Loader />
									</div>
								: chat?.length
								?(
									chat.map((el, i) => {
										const isDriver = Boolean(el?.senderId === driver?.data?.uid);
										return (
											<div key={`chat${i}`}>
												{isDriver ? (
													<div>
														<div className='d-flex justify-content-end my-4'>
															<div className=' box-shadow rounded-5 chat-bg'>
																<p className='mb-0 rounded-5 p-12 p-2 px-4  sender'>{el?.message}</p>
															</div>
															<div className='avatar ms-2' style={{ width: 30, height: 30 }}>
																<img
																	alt='...'
																	className='rounded-circle'
																	style={{ height: "100%", width: "100%", objectFit: "cover" }}
																	src={assignBucketUrl(driver?.data?.profilePicture)}
																	onError={(event) => {
																		event.target.src = "https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg";
																		event.onerror = null;
																	}}
																/>
																
															</div>
														</div>
													</div>
												) : (
													<div className='d-flex my-4'>
														<div className='me-2' style={{ width: 30, height: 30 }}>
															<img
																alt='...'
																className='rounded-circle'
																style={{ height: "100%", width: "100%", objectFit: "cover" }}
																src={assignBucketUrl(admin?.profilePicture)}
																onError={(event) => {
																	event.target.src = "https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg";
																	event.onerror = null;
																}}
															/>
															
														</div>
														<div className=' box-shadow rounded-5 bg-white'>
															<p className='mb-0 rounded-5 p-12 p-2 px-4 receiver '>{el?.message}</p>
														</div>
													</div>
												)}
											</div>
										);
									})
								):
								<div className="text-center">No data found</div>}
							
							</div>
						</div>
						<div className='border-top w-100  pb-0 mb-0' >
							<form onSubmit={formik.handleSubmit}>
								<div className='position-relative'>
									<input
										style={{ paddingRight: "5.5rem" }}
										name='message'
										value={formik.values.message}
										onChange={(e) => formik.handleChange(e)}
										type='text'
										placeholder='Your message here... '
										className={`w-100 form-control py-3 input-bg1 border border-0`}
									/>
									<div className='position-absolute chat-icon d-flex'>
										
										<div className='send-button me-2 '>
											<button type='submit' className='btn btn-primary' style={{ borderRadius: "50%", width: "100%", height: "100%", padding: "0" }}>
												<i className='bi bi-send-fill' style={{ fontSize: "15px" }}></i>
											</button>
											
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
					:
					(
									<div className='text-center'>
										<Loader />
									</div>
								)}
					
				</main>
			</Layout>
		</>
	);
};

export default Chat;
