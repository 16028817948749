import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../../app_context";
import Layout from "../../../Components/Layout";
import { ImgLoader, Loader } from "../../../Utils/Loader";
import { InputErrorMessage } from "../../../Components/ErrorMessage";
import Input from "react-phone-number-input/input";
import { ProfileForm } from "../../../Components/Forms";
import AutofillAddress from "../Register/AutofillAddress";
import { assignBucketUrl, handleFileUpload } from "../../../HelperFunctions";

const MyProfile = () => {
	const appContext = useContext(AppContext);
	const { fetchDriverProfile, state } = appContext;
	const { driverProfile } = state;
	const [loader, setLoader] = useState(false);

	const [loader2, setLoader2] = useState(false);
	const imgRef = useRef();
	const imgRef1 = useRef();
	const inputRef = useRef();
	const profileFormik = ProfileForm();

	const handleChange = (e) => {
		profileFormik.handleChange(e);
		profileFormik.setFieldValue(e.target.name, e.target.value);
	};
	const handleRemove = (name) => {
		profileFormik.setFieldValue(name, "");
		// inputRef.current.src = ProfilePicDemo;
	};

	const handleChangePhoneInput = (e) => {
		if (e?.length < 11) {

			profileFormik.setFieldValue("phone", e);
		}
	};

	useEffect(() => {
		fetchDriverProfile();
	}, []);

	useEffect(() => {
		if (driverProfile) {
			profileFormik.setValues({
				firstName: driverProfile?.data?.firstName,
				lastName: driverProfile?.data?.lastName,
				phone: driverProfile?.data?.phone,
				email: driverProfile?.data?.email,
				address: driverProfile?.data?.address,
				city: driverProfile?.data?.city,
				state: driverProfile?.data?.state,
				zipCode: driverProfile?.data?.zipCode,
				drivingLicenseNo: driverProfile?.data?.drivingLicenseNo,
				drivingLicensePicture: driverProfile?.data?.drivingLicensePicture,
				drivingLicensePictureBack: driverProfile?.data?.drivingLicensePictureBack,
				profilePicture: driverProfile?.data?.profilePicture,
			});
		}
	}, [driverProfile]);

	return (
		<Layout>
			<>
				<div>
					<div className='container px-3 mb-4 pt-3 mt-2'>
						<h3 className='fw-semibold mb-4'>My Profile</h3>
						{!driverProfile?.loading ? (
							<>
								<form onSubmit={profileFormik.handleSubmit}>
									<div className='mb-4'>
										<input
											type='text'
											className='form-control'
											aria-describedby='emailHelp'
											placeholder='Enter your first name'
											name='firstName'
											value={profileFormik.values.firstName}
											onChange={(e) => handleChange(e)}
										/>
										<InputErrorMessage error={profileFormik.touched.firstName && profileFormik.errors.firstName} />
									</div>
									<div className='mb-4'>
										<input
											type='text'
											className='form-control'
											id='exampleInputEmail1'
											aria-describedby='emailHelp'
											placeholder='Enter your last name'
											name='lastName'
											value={profileFormik.values.lastName}
											onChange={(e) => handleChange(e)}
										/>
										<InputErrorMessage error={profileFormik.touched.lastName && profileFormik.errors.lastName} />
									</div>

									<div className='mb-4'>
										<div className='position-relative'>
											<i className='bi bi-envelope-fill icon-mail1 text-muted border-start-0'></i>

											<input
												style={{ paddingRight: "32px", paddingLeft: "40px" }}
												type='text'
												className='form-control border-end-2'
												id='exampleInputEmail1'
												aria-describedby='emailHelp'
												placeholder='Enter your email'
												name='email'
												value={profileFormik.values?.email && profileFormik.values?.email}
												onChange={(e) => handleChange(e)}
											/>

											<InputErrorMessage error={profileFormik.touched.email && profileFormik.errors.email} />
										</div>
									</div>
									<div className='mb-4'>
										<div className='row'>
											<div className='position-relative'>
												<Input
													className={
														Boolean(profileFormik.touched?.phone && profileFormik.errors?.phone)
															? "form-control border-danger p-3 cu-45 e-caret-hide "
															: "cu-45 form-control p-3 e-caret-hide "
													}
													// defaultCountry='US'
													// countries={["US"]}
													country="US"
													countrySelectProps={{ unicodeFlags: true }}
													name='phone'
													placeholder='Enter phone number'
													value={profileFormik?.values?.phone}
													onChange={handleChangePhoneInput}
													initialValueFormat="national"
													international={false}
												/>

												<InputErrorMessage error={profileFormik.touched.phone && profileFormik.errors.phone} />
											</div>
										</div>
									</div>

								<AutofillAddress regFormik2={profileFormik}/>

								<div className='mb-4'>
					<input
						className={
							Boolean(profileFormik.touched?.drivingLicenseNo && profileFormik.errors?.drivingLicenseNo)
								? "form-control border-danger p-3 cu-45 e-caret-hide "
								: "cu-45 form-control p-3 e-caret-hide "
						}
						type='text' 
						id='exampleInputEmail1'
						aria-describedby='emailHelp'
						placeholder="Your driver's license number"
						name='drivingLicenseNo'
						value={profileFormik.values.drivingLicenseNo}
						onChange={profileFormik.handleChange}
					/>
					<InputErrorMessage error={profileFormik.touched.drivingLicenseNo && profileFormik.errors.drivingLicenseNo} />
				</div> 


									 

									 

				<div className='mb-4 '>
					<div
						className={
							Boolean(profileFormik.touched?.drivingLicensePicture && profileFormik.errors?.drivingLicensePicture)
								? " input-group d-flex justify-content-between align-items-center text-align-center py-1 form-control pe-1 border-danger   "
								: "input-group d-flex justify-content-between align-items-center text-align-center py-1 form-control pe-1"
						}
						
					>
						<div className='text-align-center  py-1' style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden", paddingRight: "60px" }}>
							<h6 className='text-fade1  mt-2 overflow-x-auto overflow-y-auto' style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden" }}>
								{profileFormik.values?.drivingLicensePicture ? profileFormik.values?.drivingLicensePicture?.split("/").slice(-1)[0] : "Front of your DL"}
							</h6>
						</div>
						
						<label htmlFor='licenseFile' className='position-absolute end-0'>
							<div
								className='text-fade border p-2 rounded bg me-1'
								onClick={() => {
									imgRef?.current?.click();
								}}
								style={{ cursor: "pointer" }}>
								<i className='bi bi-camera-fill'></i>
							</div>
						</label>

						<input
							type='file'
							id='licenseFile'
							name='drivingLicensePicture'
							className='form-control d-flex justify-content-end d-none'
							onChange={(e) => {
								handleFileUpload(e, profileFormik, setLoader2);
							}}
							placeholder=''
							accept='image/*'
						/>
					</div>
					<InputErrorMessage error={profileFormik.touched.drivingLicensePicture && profileFormik.errors.drivingLicensePicture} />
				</div>
				<div className='mb-4'>
					 

					<div
						className={`${
							profileFormik.touched?.drivingLicensePictureBack && profileFormik.errors?.drivingLicensePictureBack && "border-danger"
						}  input-group d-flex justify-content-between align-items-center text-align-center py-1 form-control pe-1`}
					 
					>
						<div className='text-align-center   py-1' style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden", paddingRight: "60px" }}>
							<h6 className='text-fade1  mt-2 overflow-x-auto overflow-y-auto' style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden" }}>
								{profileFormik.values?.drivingLicensePictureBack ? profileFormik.values?.drivingLicensePictureBack?.split("/")?.slice(-1)[0] : "Back of your DL"}
							</h6>
						</div>
						<div style={{ paddingRight: "5rem" }}>
						 
						</div>

						<label htmlFor='licenseFile1' className='position-absolute end-0'>
							<div
								className='text-fade border p-2 rounded bg  me-1'
								onClick={() => {
									imgRef1?.current?.click();
								}}
								style={{ cursor: "pointer" }}>
								<i className='bi bi-camera-fill'></i>
							</div>
						</label>
						<input
							type='file'
							id='licenseFile1'
							name='drivingLicensePictureBack'
							className='form-control d-flex justify-content-end d-none'
							onChange={(e) => {
								handleFileUpload(e, profileFormik, setLoader2);
							}}
							placeholder=''
							accept='image/*'
						/>
					</div>
					<InputErrorMessage error={profileFormik.touched.drivingLicensePictureBack && profileFormik.errors.drivingLicensePictureBack} />
				</div>
				 

				<div>
					<h6 className='fw-semibold'>Profile picture</h6>
					<div className='d-flex justify-content-between align-items-center mb-3 '>
						<div style={{ width: " 50px", height: " 50px", position: "relative" }} className='image-section'>
							{Boolean(profileFormik.values?.profilePicture)&&<i className='bi bi-x-circle r-0' style={{ cursor: "pointer" }} onClick={() => handleRemove("profilePicture")}></i>}

							{profileFormik.values?.profilePicture !== "" ? (
								<>
									<img
										ref={inputRef}
										src={assignBucketUrl(profileFormik.values?.profilePicture)}
										onError={(event) => {
											event.target.src = "https://driver101.s3.us-west-1.amazonaws.com/upload/files/Portrait_Placeholder.png";
											event.onerror = null;
										}}
										alt='#'
										style={{ width: "100%", height: "100%", objectFit: "cover" }}
										className='border rounded-3 '
									/>
								</>
							) : loader ? (
								<ImgLoader />
							) : (
								<img src={"https://driver101.s3.us-west-1.amazonaws.com/upload/files/Portrait_Placeholder.png"} alt='#' style={{ width: "100%", height: "100%", objectFit: "cover" }} className='border rounded-3 ' />
							)}
						</div>
						<label htmlFor='profilePic'>
							<div
								onClick={() => {
									imgRef?.current?.click();
								 
								}}
								style={{ cursor: "pointer" }}
								className={
									Boolean(profileFormik.touched?.profilePicture && profileFormik.errors?.profilePicture)
										? "btn1 py-1 h-100 rounded-2 border-danger text-danger"
										: "btn1 py-1 h-100 rounded-2"
								}
							 
							>
								Upload
							</div>
						</label>
						<input
							type='file'
							id='profilePic'
							name='profilePicture'
							onChange={(e) => {
								handleFileUpload(e, profileFormik, setLoader);
							}}
							className='d-none'
							accept='image/*'
						/>
					</div>
					<p className={`mt-2 fw-bold `} style={{ fontSize: "14px", color: "rgba(0,0,0,0.37)  " }}>
						For a good profile photo, avoid hats and sunglasses, show your full face and top of shoulders, use good lighting, and avoid filters or photoshopped
						images.
					</p>
					<InputErrorMessage error={profileFormik.touched.profilePicture && profileFormik.errors.profilePicture} />
				</div>


									<div className='text-center my-4'>
										<button type='submit' className='btn text-white w-100 py-3'>
											Save
										</button>
										<div className="extraHeight"></div>
									</div>
								</form>
							</>
						) : (
							""
						)}
						{driverProfile?.loading ? (
							<div className='text-center mt-8'>
								<Loader />
							</div>
						) : (
							""
						)}
					</div>
				</div>
			</>
		</Layout>
	);
};

export default MyProfile;
