import React from "react";
import Layout from "../Components/Layout";
import gasStation from "../Assets/images/Icon material-local-gas-station.svg";
import map from "../Assets/images/map.png";
import { Link } from "react-router-dom";

const Gas = () => {
  return (
    <Layout>
      <div className='main-content '>
        <div className='pt-5 container'>
          <div className='pt-5'></div>
          <div className='card position-relative mb-3' style={{ border: "1px solid #E9E9E9" }}>
            <div className='dark-color text-white rounded-pill text-center py-1 px-4 customerActive p-11'>Active 07:58:32 pm</div>

            <div className='card-body mt-3'>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Started</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>Fri, Jan 13 9 AM</div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Estimated duration</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>1 h 20 min</div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Estimated time arrival</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>Fri, Jan 13 10:30 AM</div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Must arrive latest</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>Fri, Jan 13 11 AM</div>
              </div>
              <div className='mb-2 py-2 rounded-2' style={{ backgroundColor: "#E7EFFF" }}>
                <div className='d-flex justify-content-center '>
                  <img src={gasStation} alt='' />
                  <p className='m-0 ms-2 fw-semibold light-clr'>Add Gas receipt</p>
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Buyer</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>Jake Buffett</div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Phone Number</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>3109878655</div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>License plate #</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>OTM-199</div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Make and Model</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>Toyota Avenses-2012</div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Transmission</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>Automatic</div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Distance</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>40 miles</div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Estimated duration</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>1h 30min</div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Estimated time arrival</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>1h 30min</div>
              </div>
              <div className='row mb-2'>
                <div className='col-5 text-fade p-11'>Must arrival latest</div>
                <div className='col-1 text-fade'>:</div>
                <div className='col-6 p-14 fw-semibold '>1h 30min</div>
              </div>
            </div>
          </div>
          <div className='text-center'>
            <Link to='/pickup-details'>go next</Link>
            <img src={map} alt='' />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Gas;
