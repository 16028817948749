import React, { useContext } from "react";
import home from "../../Assets/images/Icon ionic-md-home.svg";
import car from "../../Assets/images/Icon awesome-car.svg";
import search from "../../Assets/images/Icon feather-search.svg";
import user from "../../Assets/images/Icon feather-user.svg";
import { Link } from "react-router-dom";
import AppContext from "../../app_context";

const Footer = () => {
  const { setGigState } = useContext(AppContext);
  const handleClearStep = () => {
    setGigState((v) => ({ ...v, step: 1 }));
  };

  return (
    <div className='d-flex justify-content-around align-items-center border-top bg-white py-2 position-fixed className="text-decoration-none" container bottom-0'>
      <div>
        <Link
          to='/'
          onClick={() => {
            handleClearStep();
          }}
          className='text-decoration-none'>
          <div className='rounded-5 text-center'>
            <img src={home} alt='' />
            <p className='m-0 p-10'>Home</p>
          </div>
        </Link>
      </div>
      <div>
        <Link
          to='/my-bookings'
          onClick={() => {
            handleClearStep();
          }}
          className='text-decoration-none'>
          <div className='rounded-5 text-center '>
            <img src={car} alt='' className='m-auto' />
            <p className='m-0 p-10'>My Bookings</p>
          </div>
        </Link>
      </div>
      <div>
        <Link
          to='/giglist'
          onClick={() => {
            handleClearStep();
          }}
          className='text-decoration-none'>
          <div className='rounded-5 text-center'>
            <img src={search} alt='' />
            <p className='m-0 p-10'>Gig List</p>
          </div>
        </Link>
      </div>
      <div>
        <Link
          to='/my-profile'
          onClick={() => {
            handleClearStep();
          }}
          className='text-decoration-none'>
          <div className='rounded-5 text-center'>
            <img src={user} alt='' />
            <p className='m-0 p-10'>My Profile</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
