import { toast } from "react-hot-toast";
import { uid } from "uid";
import { fileUploadApi, getGig, multiFileUploadApi } from "../Api";
import { API_BASE_URL } from "../Utils/BaseUrl";

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

export const logoutOnJwtExpire = () => {
	localStorage.clear();
	window.location.href = "/";
};

export const assignBucketUrl = (path) => {
	return encodeURI(path?.startsWith("upload/") ? API_BASE_URL + "/" + path : path);
};

export const getLastElementId = () => {
	const path = window.location.pathname.split("/");
	if (path && path[path.length - 1]?.length !== 1) {
		return path[path.length - 2];
	}
};

export const getLastElementId2 = () => {
	const path = window.location.pathname.split("/");
	if (path[path.length - 1]?.length == 1) {
		return path[path.length - 2];
	} else {
		return path[path.length - 1];
	}
};

export const getPageId = () => {
	const path = window.location.pathname.split("/");
	if (path && path[path.length - 1]?.length === 1) {
		return path[path.length - 1];
	}
};

export const getToken = () => localStorage.getItem("token");
export const setToken = (token) => localStorage.setItem("token", token);
export const removeToken = () => localStorage.removeItem("token");
export const isAuth = () => Boolean(getToken());

export const handleFileUpload = async (event, formik, setLoader) => {
	const logoImg = event.target?.files?.[0];
	if (!logoImg) {
		return;
	}
	const loadingToast = toast.loading("File Uploading...", { id: "0001" });

	const imgFile = new FormData();
	imgFile.append("file", logoImg);

	if (logoImg.size > 15000000) {
		toast.dismiss(loadingToast);
		toast.error("Please upload file less than 15 mb", { id: "etrr01" });
		formik.setFieldValue(event.target.name, "");
		setLoader(false);
		return;
	}

	try {
		setLoader(true);
		if (logoImg.name.match(/\.(jpg|jpeg|png|jfif|webp|JPG|JPEG|PNG|JFIF|WEBP)$/)) {
			const res = await fileUploadApi(imgFile);
			if (res.status == 200) {
				formik.setFieldValue(event.target.name, res?.data?.filePath);
				setLoader(false);
				toast.dismiss(loadingToast);
				toast.success(res?.data?.message, { id: "001" });
			} else {
				setLoader(false);
			}
		} else {
			setLoader(false);
			toast.dismiss(loadingToast);
			toast.error("Unsupported Image Format", { id: "err091" });
		}
	} catch (error) {
		setLoader(false);

		toast.dismiss(loadingToast);
	}
};

export const fetchGig = async (id, setGigState) => {
	setGigState((v) => ({ ...v, gigData: { loading: true } }));
	try {
		const res = await getGig(id);
		if (res.status === 200) {
			setGigState((v) => ({ ...v, gigData: { data: res?.data, loading: false } }));
		}
	} catch (err) {
		setGigState((v) => ({ ...v, gigData: { loading: false } }));
	}
};

export const handleFileUploadForPickDrop = (e, imageFiles, setImageFiles, formik) => {
	let { files } = e.target;

	for (let i = 0; i < files.length; i++) {
		const file = files[i];
		file.id = uid();
		// if (file.type.match(imageTypeRegex)) {
		imageFiles = [...imageFiles, file].sort((a, b) => a.size - b.size);
		// }

		if (imageFiles.length <= 10) {
			setImageFiles([...imageFiles]);
		} else {
			toast.error("Images shouldn't be more than 10", { id: "etrr01" });
		}
	}
	imageFiles?.length < 6 && toast(`Please add minimum 6 images`, { icon: "📸", id: "09" });
	// if (imageFiles.length >= 6) {
	//   document.getElementById("photos").disabled = true;
	//   document
	//     .getElementById("addBtn1")
	//     .setAttribute("class", "changeFileBtn btn text-white py-1 h-100 rounded-2 bg-secondary");
	//   document.getElementById("addBtn1").setAttribute("style", " cursor :inherit");
	// }
	formik.setFieldValue("photos", imageFiles);
};

export const handleFileRemoveForPickDrop = (imageFiles, setImageFiles, i, formik, inputRef) => {
	inputRef.current.value = null;

	const name = imageFiles[i]?.id;

	if (imageFiles.length < 10) {
		document.getElementById("photos").disabled = false;
		document.getElementById("addBtn1").setAttribute("class", "changeFileBtn btn text-white py-1 h-100 rounded-2");
		document.getElementById("addBtn1").setAttribute("style", "cursor: pointer");
	}

	const fltr = imageFiles.filter((el, i) => {
		return el.id !== name;
	});

	if (fltr.length > 0) {
		formik.setFieldValue("photos", fltr);
		return setImageFiles([...fltr]);
	} else {
		formik.setFieldValue("photos", "");
		return setImageFiles([...fltr]);
	}
};

export const helperImageUpload = async (formik, event, orderId, setImages) => {
	const logoImg = event?.target?.files;

	const formdata = new FormData();
	const loadingToast = toast.loading("File Uploading...", { id: "0002" });
	let isValidLength = Object.values(logoImg)?.length === 6;

	for (let i = 0; i < event.target.files.length; i++) {
		formdata.append("file", event.target.files[i]);
	}

	const isCorrectFormat = Object.values(logoImg)?.every((el) => {
		let format = el?.name.split(".")?.slice(-1)[0].toLowerCase();
		let isValidFormat = format === "png" || format === "jpg" || format === "jpeg";
		return isValidFormat;
	});

	if (isCorrectFormat) {
		if (isValidLength) {
			const response = await multiFileUploadApi(formdata, orderId);
			let isSucceed = response.status === 200 || response.status === 201;
			if (isSucceed) {
				setImages(response?.data?.filePath);
				toast.dismiss(loadingToast);
				toast.success(response?.data?.message, { id: "0011" });
				formik?.setFieldValue(event?.target?.name, response?.data?.filePath);
			}
		} else {
			toast.dismiss(loadingToast);
			toast("Only 6 images allowed", { icon: "⚠️" });
		}
	} else {
		toast.dismiss(loadingToast);
		toast("Unsupported File Format", { icon: "⚠️" });
	}
};

export const handleMapClick = (address) => {
	window.open(`https://www.google.com/maps?q=${encodeURIComponent(address)}`, "_blank");
};

export const formatPhoneNumber = (value) => {
	const phoneNumber = value?.phone?.replace(/[^\d]/g, "");
	const phoneNumberLength = phoneNumber?.length;

	if (phoneNumberLength < 4) return phoneNumber;
	if (phoneNumberLength < 7) {
		return `(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4)}`;
	}

	return (
		<h6 className='fw-bold mb-4'>
			Driver, call the car owner {`${value?.name}`}{" "}
			<a href={`tel:${value?.phone}`} className='text-decoration-none'>
				{" "}
				{`(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`}
			</a>{" "}
			and agree the delivery time.
		</h6>
	);
};

export const phoneNumberInputformat = (value) => {
	const phoneNumber = value?.phone?.replace(/[^\d]/g, "");
	const phoneNumberLength = phoneNumber?.length;

	if (phoneNumberLength < 4) return phoneNumber;
	if (phoneNumberLength < 7) {
		return `(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4)}`;
	}

	return (
		<div className='position-relative'>
			<input
				className='form-control e-caret-hide'
				defaultCountry='US'
				countries={["US"]}
				name='phone'
				placeholder=' Enter phone number'
				value={`(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`}
				disabled={true}
			/>
			<i className='bi bi-telephone-fill icon-mail text-muted border-start-0'></i>
		</div>
	);
};


export const formatPhoneNumber3 = (data) => {
	const phoneNumber = data?.customerPhone?.replace(/[^\d]/g, "");
	const phoneNumberLength = phoneNumber?.length; 
	if (phoneNumberLength < 4) return phoneNumber;
	if (phoneNumberLength < 7) {
	  return `(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4)}`;
	}
  
	return (
		<a className="text-decoration-none dark-clr" href={`tel:${data?.phone}`}> {`(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`}</a> 
    );
  };