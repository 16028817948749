import React, { useContext, useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import { getBookingApi } from "../../Api";
import Select from "react-select";
import { Loader } from "../../Components/Loaders";
import Pagination from "../../Components/Pagination";
import GigListMobile from "../../Components/GigList/Mobile";
import GigListDesktop from "../../Components/GigList/Desktop";
import { GigContext } from "../../MainRouter/MainRouter";

const MyBooking = () => {
	const [bookState, setBookState] = useState({
		filter: { page: 1, limit: 10, filter: `booked` },
		bookingData: { data: "", loading: true, length: 0 },
	});

	const { pageId, setPageId } = useContext(GigContext);

	const getMyBookings = async (values) => {
		try {
			setBookState((v) => ({ ...v, bookingData: { loading: true, length: 0 } }));
			const res = await getBookingApi(values);
			if (res.status === 200) {
				setBookState((v) => ({
					...v,
					bookingData: {
						data: res?.data?.bookings,
						loading: false,
						length: res?.data?.totalGigs || 0,
						totalPages: res?.data?.pages,
					},
				}));
			}
		} catch (err) {
			setBookState((v) => ({ ...v, bookingData: { data: "", loading: false, length: 0 } }));
		}
	};

	const opns = [
		{ value: "booked", label: "📅 Upcoming" },
		{ value: "started", label: "🚙 Active" },
		{ value: "completed", label: "🗓️ Past" },
		// { value: "expired", label: "⌛ Expired" },
	];

	const handleChange = (name, value) => {
		setBookState((v) => ({ ...v, filter: { ...bookState?.filter, [name]: value } }));
	};

	const handlePageClick = ({ selected }) => {
		handleChange("page", selected + 1);
	};

	const paginate = () => {
		return <Pagination pageCount={bookState?.bookingData?.totalPages} onPageChange={handlePageClick} itemsPerPage={10} />;
	};

	useEffect(() => {
		getMyBookings(bookState?.filter);
		paginate();
	}, [bookState?.filter]);

	return (
		<Layout>
			<main className='container main-content-container'>
				<div className='row align-item-center'>
					<div className='col-lg-9'>
						<h6 className='pt-4 mb-4 fw-bold'>My Bookings</h6>
					</div>
					<div className='dropdown col-lg-3 pt-3'>
						<Select
							className='basic-single form-select p-0'
							classNamePrefix='select'
							defaultValue={opns[0]}
							name='color'
							isSearchable={false}
							options={opns}
							onChange={(e) => {
								setBookState((v) => ({ ...v, filter: { ...bookState?.filter, filter: e.value, page: 1 } }));
							}}
						/>
					</div>
				</div>
			</main>

			<div className='bg-clr  my-3'>
				<h4 className='text-center fw-bold py-3 p-14'>
					{bookState?.bookingData?.data ? bookState?.bookingData?.length : 0} {bookState?.filter?.filter} gigs
				</h4>
			</div>
			<div className='container'>
				{bookState?.bookingData?.loading ? (
					<div className='container main-content-container text-center mt-4'>
						<Loader />
					</div>
				) : bookState?.bookingData?.data?.length >= 1 ? (
					bookState?.bookingData?.data?.map((el, i) => {
						const isDelOrCancl = Boolean(el?.status === "completed" || el?.status === "cancelled");
						const isOnlyCncl = Boolean(el?.status === "cancelled");
						const isOnlyDel = Boolean(el?.status === "completed");
						const props = {
							isDelOrCancl,
							isOnlyCncl,
							isOnlyDel,
							el,
							i,
							pageId,
							setPageId,
							driverId: el?.driverId,
						};
						return (
							<div className='row' key={`book${i}`}>
								<GigListDesktop val={props} />
								<GigListMobile val={props} />
							</div>
						);
					})
				) : (
					<div className='container main-content-container text-center mt-4'>
						<i>No data found</i>
					</div>
				)}
			</div>
			{paginate()}
		</Layout>
	);
};

export default MyBooking;
