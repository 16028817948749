import React, { useCallback, useEffect, useRef, useState } from "react";
import { getLocationInputApi } from "../../../Api";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import SelectStyle from "../../../Utils/customSelectStyle";
import { debounce } from "lodash";
import { ImgLoader, ImgLoader2, Loader2 } from "../../../Utils/Loader";
import { InputErrorMessage } from "../../../Components/ErrorMessage";
// const apiKey = `AIzaSyBOZPRBIMUHB829qNCfYqAMTPGJpNtWAoo`;
const apiKey = "AIzaSyCJ4HYY3az2y8ek1oNy0eG4OTmr3RtPBh0";
// const apiKey = "AIzaSyCaCSJ0BZItSyXqBv8vpD1N4WBffJeKhLQ"
// const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

const extractAddress = (place, formik) => {
	const address = {
		city: "",
		state: "",
		zip: "",
	};

	if (!Array.isArray(place?.address_components)) {
		return address;
	}

	place.address_components.forEach((component) => {
		const types = component.types;
		const value = component.long_name;

		if (formik && types.includes("locality")) {
			formik.values.city = value;
			address.city = value;
		}

		if (types.includes("administrative_area_level_1")) {
			// formik.values.state = `${value}, CA`
			formik.values.state = component.short_name;
			address.state = value;
		  }

		if (formik && types.includes("postal_code")) {
			formik.values.zipCode = value;
			address.zip = value;
		}
	});

	return address;
};

const AutofillAddress = ({ regFormik2 }) => {
	const searchInput = useRef(null);
	const [address, setAddress] = useState({});
	const [demoAdd, setDemoAdd] = useState("");
	const [fieldLoader, setFieldLoader] = useState(false);

	const [cords, setCords] = useState({ latD: "", lngD: "" });

	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				<span className='custom-css-class'>Search your address</span>
			</components.NoOptionsMessage>
		);
	};

	const reverseGeocode = ({ latD: lat, lngD: lng }) => {
		setFieldLoader(true);
		const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
		searchInput.current.value = "Getting your location...";
		fetch(url)
			.then((response) => response.json())
			.then((location) => {
				const place = location.results[0];
				const _address = extractAddress(place, regFormik2);
				setAddress(_address);
				setFieldLoader(false);
			});
	};

	const handleLocationChange = async (search) => {
		try {
			const res = await getLocationInputApi(search);
			// const res = await getLocationByPlaceId(placeId);

			if (res.status === 200 && res?.data?.length) {
				const rd = res.data.map((e) => {
					return {
						label: e.name + " " + e.formatted_address,
						value: e.name + " " + e.formatted_address,
						lat: e.geometry.location.lat.toString(),
						lng: e.geometry.location.lng.toString(),
						cityName: Boolean(e?.plus_code?.compound_code) ? e?.plus_code?.compound_code?.split(",")[0].split(" ").slice(1).join(" ") : e?.name,
						place_id: e.place_id,
					};
				});
				return rd;
			}
			return null;
		} catch (error) {
			return null;
		}
	};

	const fetchLocationArray = useCallback(
		debounce((inputText, callback) => {
			handleLocationChange(inputText).then((options) => callback(options));
		}, 300),
		[]
	);

	useEffect(() => {
		if (demoAdd) {
			reverseGeocode(cords);
		}
	}, [demoAdd, regFormik2?.values?.address]);

	return (
		<>
			<div className='App'>
				<div>
					<div className='address'>
						<div className='mb-3s'>
							<label htmlFor='exampleInputEmail1' className='form-label fw-semibold'>
								Address
							</label>
							<AsyncSelect
								components={{ NoOptionsMessage }}
								styles={SelectStyle}
								ref={searchInput}
								cacheOptions
								loadOptions={fetchLocationArray}
								onChange={(f) => {
									setDemoAdd(f);
									setCords({ latD: f.lat, lngD: f.lng });
									setTimeout(() => {
										regFormik2?.setFieldValue("address", f.value);
									}, 200);
									this?.blur();
								}}
								defaultOptions
								name='address'
								className={Boolean(regFormik2?.touched?.address && regFormik2?.errors?.address) ? "form-control border-danger p-0" : "form-control p-0"}
								value={{ value: regFormik2?.values?.address, label: regFormik2?.values?.address }}
								onFocus={() => {
									regFormik2?.setFieldValue("address", "");
									regFormik2?.setFieldValue("city", "");
									regFormik2?.setFieldValue("state", "");
									regFormik2?.setFieldValue("zipCode", "");
								}}
							/>
							<InputErrorMessage error={regFormik2?.touched.address && regFormik2?.errors?.address} />

							{/* {Boolean(regFormik2?.touched.address && regFormik2?.errors?.address) && <p className='text-danger mt-2'>{regFormik2?.errors?.address}</p>} */}
						</div>
						<div className='d-flex justify-content-end align-items-end'>
							{/* <button className='btn1 py-1 h-100 rounded-2 mt-4 mb-4 position-relative' onClick={findMyLocation}>click me</button> */}
						</div>
						<div className='row g-3 mb-3 mt-1'>
							<div className='col-12 '>
								{/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">City</label> */}
								<input
									type='text'
									className={Boolean(regFormik2?.touched?.city && regFormik2?.errors?.city) ? "form-control border-danger p-3" : "form-control p-3 "}
									id='exampleInputEmail1'
									aria-describedby='emailHelp'
									placeholder='City'
									name='city'
									value={regFormik2?.values.city}
									onChange={regFormik2?.handleChange}
								/>
								<InputErrorMessage error={regFormik2?.touched.city && regFormik2?.errors?.city} />

								{/* {Boolean(regFormik2?.touched.city && regFormik2?.errors?.city) && <p className='text-danger mt-2'>{regFormik2?.errors?.city}</p>} */}
								{fieldLoader ? (
									<div className='text-start'>
										<ImgLoader />
									</div>
								) : (
									""
								)}
							</div>
						</div>
						<div className='row g-3 mb-3'>
							<div className='col-6'>
								{/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">State</label> */}
								<input
									type='text'
									className={Boolean(regFormik2?.touched?.state && regFormik2?.errors?.state) ? "form-control border-danger p-3" : "form-control p-3 "}
									id='exampleInputEmail1'
									aria-describedby='emailHelp'
									placeholder='State'
									name='state'
									value={regFormik2?.values.state}
									onChange={regFormik2?.handleChange}
								/>
								<InputErrorMessage error={regFormik2?.touched.state && regFormik2?.errors?.state} />
								{/* {Boolean(regFormik2?.touched.state && regFormik2?.errors?.state) && <p className='text-danger mt-2'>{regFormik2?.errors?.state}</p>} */}
								{fieldLoader ? (
									<div className='text-start'>
										<ImgLoader />
									</div>
								) : (
									""
								)}
							</div>
							<div className='col-6'>
								{/* <label htmlFor="exampleInputEmail1" className="form-label fw-semibold">Zip</label> */}
								<input
									type='text'
									className={Boolean(regFormik2?.touched?.zipCode && regFormik2?.errors?.zipCode) ? "form-control border-danger p-3" : "form-control p-3 "}
									id='exampleInputEmail1'
									aria-describedby='emailHelp'
									placeholder='Zip Code'
									name='zipCode'
									value={regFormik2?.values.zipCode}
									onChange={regFormik2?.handleChange}
								/>
								<InputErrorMessage error={regFormik2?.touched.zipCode && regFormik2?.errors?.zipCode} />

								{/* {Boolean(regFormik2?.touched.zipCode && regFormik2?.errors?.zipCode) && <p className='text-danger mt-2'>{regFormik2?.errors?.zipCode}</p>} */}
								{fieldLoader ? (
									<div className='text-start'>
										<ImgLoader />
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AutofillAddress;
