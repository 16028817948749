import React, { useState } from "react";
import { Link } from "react-router-dom";
import { WhiteLoader } from "../../../Components/Loaders";
import signInImg from "../../../Assets/images/image_2.jpeg";
import logo from "../../..//Assets/images/Group 3621.png";
import { InputErrorMessage } from "../../../Components/ErrorMessage";
import { Loginform } from "../../../Components/Forms";

function SignIn() {
	const [passwordType, setPasswordType] = useState("password");
	const [keepLoggIn, setKeepLoggIn] = useState({ keepLogIn: "", loading: "" });

	const loginFormik = Loginform(setKeepLoggIn);

	return (
		<>
			<div className='container px-3'>
				<div style={{ width: "90px", height: "60px" }} className=' my-4'>
					<img src={logo} alt='#' style={{ width: "100%", height: "100%" }} />
				</div>
				<h2 className='fw-bold mt-4 p-25 mb-0'>Welcome!</h2>
				<p className='text-muted mb-4 p-14 fw-semibold'>Please, log in to your account.</p>
			</div>
			<div className='container   px-0 mb-4 ' id='img-login-bg'>
				<img src={signInImg} alt='' style={{ height: "100%", width: "100%", objectFit: "contain" }} />
			</div>
			<form className='container px-3' onSubmit={loginFormik.handleSubmit}>
				<div className='mb-4'>
					<div className='position-relative'>
						<input
							type='email'
							className='form-control border-end-2'
							id='exampleInputEmail1'
							placeholder='Email '
							name='email'
							value={loginFormik.values.email}
							onChange={loginFormik.handleChange}
						/>
						<i className='bi bi-envelope-fill  text-muted icon-mail'></i>
					</div>
					<InputErrorMessage error={loginFormik.touched.email && loginFormik.errors.email} />
				</div>
				<div className='mb-4'>
					<div className='position-relative'>
						<input
							type={passwordType}
							className='form-control border-end-2 pe-5'
							id='exampleInputPassword1'
							placeholder='Password'
							name='password'
							value={loginFormik.values.password}
							onChange={loginFormik.handleChange}
						/>
						<i
							className={`bi ${passwordType === "password" ? "bi-eye-slash-fill" : "bi-eye-fill"} icon-mail text-muted border-start-0 eye-pointer`}
							onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")}></i>
					</div>
					<InputErrorMessage error={loginFormik.touched.password && loginFormik.errors.password} />
				</div>
				<div className='d-flex justify-content-between align-items-center my-3'>
					<div className='d-flex align-items-center mb-3'>
						<input
							type='checkbox'
							name='keepLoggedIn'
							value={loginFormik.values.keepLoggedIn}
							onChange={(e) => loginFormik.setFieldValue("keepLoggedIn", e.target.checked ? true : false)}
						/>
						<span>
							<h6 className='my-auto ps-2 text-black fw-semibold p-14 '>Remember me</h6>
						</span>
					</div>
					<InputErrorMessage error={loginFormik.touched.keepLoggedIn && loginFormik.errors.keepLoggedIn} />

					<Link to='/forgot-password' className='mb-3 form-check text-end  p-14 text-decoration-none fw-semibold' style={{ color: "#4488bf" }}>
						Forgot password?
					</Link>
				</div>

				<div className='text-center my-3'>
					<button type='submit' className='btn text-white w-100 py-3 fw-semibold'>
						{keepLoggIn.loading ? (
							<div className='text-center  '>
								<WhiteLoader />
							</div>
						) : (
							"Log in"
						)}
					</button>
				</div>
				<div className='text-center text-muted'>
					<p className='firstCap'>
						<span className='me-2 p-14'>Don't have an account?</span>
						<Link to='/sign-up' className='fw-semibold fs-6 text-decoration-none'>
							<p className='fw-semibold fs-6 text-decoration-none d-inline-block' style={{ color: "#4488bf" }}>
								Sign up
							</p>
						</Link>
					</p>
				</div>
			</form>
		</>
	);
}

export default SignIn;
