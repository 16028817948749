import { useJsApiLoader, GoogleMap, DirectionsRenderer, Marker } from "@react-google-maps/api";

import { useEffect, useRef, useState, useCallback } from "react";
import { GoogleMapApiKey } from "../../Utils/BaseUrl";
import { getCoordsApi } from "../../Api";
import { Loader } from "../Loaders";

let map, infoWindow;

function handleLocationError(browserHasGeolocation, infoWindow, pos) {
  infoWindow.setPosition(pos);
  infoWindow.setContent(
    browserHasGeolocation
      ? "Error: The Geolocation service failed."
      : "Error: Your browser doesn't support geolocation."
  );
  infoWindow.open(map);
}

function animateMarkerTo(marker, newPosition) {  
  var options = {
    duration: 1000,
    easing: function (x, t, b, c, d) {
      // jquery animation: swing (easeOutQuad)
      return -c * (t /= d) * (t - 2) + b;
    }
  };

  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;
  window.cancelAnimationFrame =
    window.cancelAnimationFrame || window.mozCancelAnimationFrame;

  // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
  marker.AT_startPosition_lat = marker.getPosition().lat();
  marker.AT_startPosition_lng = marker.getPosition().lng();
  var newPosition_lat = newPosition.lat();
  var newPosition_lng = newPosition.lng();

  // crossing the 180° meridian and going the long way around the earth?
  if (Math.abs(newPosition_lng - marker.AT_startPosition_lng) > 180) {
    if (newPosition_lng > marker.AT_startPosition_lng) {
      newPosition_lng -= 360;
    } else {
      newPosition_lng += 360;
    }
  }

  var animateStep = function (marker, startTime) {
    var ellapsedTime = new Date().getTime() - startTime;
    var durationRatio = ellapsedTime / options.duration; // 0 - 1
    var easingDurationRatio = options.easing(
      durationRatio,
      ellapsedTime,
      0,
      1,
      options.duration
    );

    if (durationRatio < 1) {
      marker.setPosition({
        lat:
          marker.AT_startPosition_lat +
          (newPosition_lat - marker.AT_startPosition_lat) * easingDurationRatio,
        lng:
          marker.AT_startPosition_lng +
          (newPosition_lng - marker.AT_startPosition_lng) * easingDurationRatio
      });

      // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
      if (window.requestAnimationFrame) {
        marker.AT_animationHandler = window.requestAnimationFrame(function () {
          animateStep(marker, startTime);
        });
      } else {
        marker.AT_animationHandler = setTimeout(function () {
          animateStep(marker, startTime);
        }, 17);
      }
    } else {
      marker.setPosition(newPosition);
    }
  };

  // stop possibly running animation
  if (window.cancelAnimationFrame) {
    window.cancelAnimationFrame(marker.AT_animationHandler);
  } else {
    clearTimeout(marker.AT_animationHandler);
  }

  animateStep(marker, new Date().getTime());
}

const Map = ({ from, to, liveLongLat }) => {
  const [centerCoord, setCenterCoords] = useState();
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [map, setMap] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const originRef = useRef();
  const destiantionRef = useRef();
  const markerRef = useRef(null);
  const center = [centerCoord && centerCoord];

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GoogleMapApiKey,
    libraries: ["places"],
    id: "google-map-script"

  });
  // const getCoords = async (pickAdd) => {
  //   const res = await getCoordsApi(pickAdd);
  //   if (res.status === 200) {
  //     setCenterCoords(res?.data[0].geometry.location);
  //   }
  // };

  const calculateRoute = async () => {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: from,
      destination: to,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
  };

 
  
  useEffect(()=>{
    console.log(liveLongLat,'liveLongLat')
    if(Boolean(liveLongLat?.lattitude) && Boolean(liveLongLat?.longitude)){
    animateMarkerTo(markerRef.current.marker, {lat:()=>{return liveLongLat.lattitude}, lng:()=>{return liveLongLat.longitude}});
    }
  },[liveLongLat])

  useEffect(() => {
    if (from && to) {
      setTimeout(() => {
        calculateRoute();
      }, 500);
    }
  }, [from, to]);

  const onClick = useCallback((event) => { 
    console.log(event,'yyy')
    animateMarkerTo(markerRef.current.marker, event.latLng);
  }, []);

  if (!isLoaded) {
    return <div>Please wait ...</div>;
  }

  // function clearRoute() {
  //   setDistance("");
  //   setDuration("");
  //   originRef.current.value = "";
  //   destiantionRef.current.value = "";
  // }

  return (
    <div style={{ height: "100%", zIndex: -1 }}>
      {!isLoaded ? (
        <div className='text-center mt-8'>
          <Loader />
        </div>
      ) : (
        <GoogleMap
          // center={center}
          zoom={15}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          gestureHandling: 'none',
          }}
        onClick={(e) => onClick(e)}

          onLoad={(map) => setMap(map)}>
          {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
          <Marker
          icon={{
            url: "https://driver101.s3.us-west-1.amazonaws.com/upload/files/ts-map-pin.png",
            scaledSize: new window.google.maps.Size(30, 30), 
          }}
          position={{lat:liveLongLat?.lattitude||0, lng: liveLongLat?.longitude||0}}
          ref={markerRef}
        />
        </GoogleMap>
      )}
    </div>
  );
};

export default Map;
