import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <div className=' position-relative container p-0'>
      <Header />
      <div className='scrollHide' style={{height: 'calc(100vh - 120px)', overflow: 'hidden', overflowY: 'auto', marginTop: '3.7rem'}}> {children}</div>
      {/* <div className="extraHeight"></div> */}
      <Footer />
    </div>
  );
};

export default Layout;
