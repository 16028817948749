import React, { createContext, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { confirmGigApi, getNearGigs } from "../../Api";
import success from "../../Assets/images/success.png";
import { fetchGig, getLastElementId2, getPageId } from "../../HelperFunctions";
import moment from "moment";
import { Route, Routes, useNavigate } from "react-router-dom";
import { GigContext } from "../../MainRouter/MainRouter";
import DealerDetails from "./DealerDetails";
import GigBook from "./GigBook";
import GigBookConfirm from "./GigBookConfirm";
import Instructions from "./Instructions";

export const gigContext = createContext();

const Index = () => {
	const { gigState, setGigState, pageId, setPageId } = useContext(GigContext);
	const loader = Boolean(gigState?.gigData?.loading);

	const longLat = gigState?.gigData?.data && {
		latitude: gigState?.gigData?.data?.fromLatitude,
		longitude: gigState?.gigData?.data?.fromLongitude,
	};
	const navigate = useNavigate();
	const orderId = getLastElementId2();
	const lastId = getPageId();
	const stepNo = parseInt(lastId);

	const isZeroMins = (time) => {
		const pickTime = moment(time).format("ddd. MMM. D (h:mm A)");
		const timeOnly = pickTime.split("(")?.slice(-1)[0];
		const dateOnly = pickTime.split(" (")?.slice(0)[0];
		const mins = timeOnly.split(":")?.slice(-1)[0];

		if (mins.includes("00")) {
			return `${dateOnly}, (${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]})`;
		} else {
			return `${dateOnly}, (${timeOnly.split(")")?.slice(0)[0]})`;
		}
	};

	const fetchNearGigs = async (payload) => {
		setGigState((v) => ({ ...v, nearbyGigs: { data: null, loading: true } }));
		try {
			const res = await getNearGigs(payload);
			if (res.status === 200) {
				setGigState((v) => ({ ...v, nearbyGigs: { data: res?.data || null, loading: false } }));
			}
		} catch (err) {
			setGigState((v) => ({ ...v, nearbyGigs: { data: null, loading: false } }));
		}
	};

	useEffect(() => {
		setPageId(stepNo);
	}, [navigate]);

	const confirmBooking = async (orderId) => {
		setGigState((v) => ({ ...v, modal: { isOpen: false, loading: true } }));
		try {
			const res = await confirmGigApi({ orderId });
			if (res.status === 200) {
				toast(
					<div className='gigToast'>
						<div className=' modal-dialog modal-dialog-centered'>
							<div className='modal-content p-2 pb-3'>
								<div className="modal-body P-2">
									<img src={success} alt='' className='m-auto' style={{ width: "100%" }} />

									<div className='text-center mb-2 pb-2'>
										
										<h1 className='mb-2 fw-bold text-dark'>Congratulations</h1>
										<p className='m-0 text-dark' style={{  }}>
											it's yours!
										</p>
									</div>

									<div className='d-flex justify-content-between'>
										<button
											className='button btn-primary py-2 h4 fw-normal my-2'
											onClick={() => {
												navigate(`/gig-details/${orderId}/${pageId + 1}`);
												setGigState((v) => ({ ...v, modal: { isOpen: false, loading: false } }));
											}}>
											Continue
										</button>

										<button className='button btn-primary py-2 h4 fw-normal my-2' onClick={() => navigate(`/`)}>
											Drive Later
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>,
					{ autoClose: false }
				);
				setGigState((v) => ({ ...v, modal: { isOpen: true, loading: false } }));
			}
		} catch (err) {
			toast(
				<div className='gigToast'>
					<div className=' modal-dialog modal-dialog-centered'>
						<div className='modal-content p-2 pb-3'>
							<div className="modal-body text-center">
								<i
									className='bi bi-emoji-frown'
									style={{
										margin: " 0 auto",
										textAlign: "center",
										fontSize: "9rem",
									}}></i>

								<div className='text-center mb-2 pb-2'>
									<h1 className='mb-2 fw-bold text-dark'>Oops!</h1>
									<p className='m-0 text-dark' style={{ fontSize: "16px", lineHeight: "1.3" }}>
										You're late for few minutes <br /> Please try to book another gig.{" "}
									</p>
								</div>

								<button
									className='button btn-primary w-50 py-2 h5 fw-normal m-auto'
									onClick={() => {
										navigate("/");
										setGigState((v) => ({ ...v, modal: { isOpen: false, loading: false } }));
									}}>
									Back
								</button>
							</div>
						</div>
					</div>
				</div>,
				{ autoClose: false }
			);
			setGigState((v) => ({ ...v, modal: { isOpen: true, loading: false } }));
		}
	};

	let totalTime = () => {
		let estTime = gigState?.gigData?.data?.estimateEndTime;
		return parseInt(estTime?.split(":")?.slice(0)[0] * 60) + parseInt(estTime?.split(":")?.slice(1)[0]);
	};

	useEffect(() => {
		if (gigState?.gigData.data) {
			fetchNearGigs(longLat);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gigState?.gigData]);

	return (
		<>
			<gigContext.Provider
				value={{
					gigState,
					setGigState,
					confirmBooking,
					fetchGig,
					isZeroMins,
					totalTime,
					orderId,
					pageId,
					setPageId,
					loader,
				}}>
				<Routes>
					{stepNo === 1 && <Route path={`/:pageId`} element={<GigBook />} />}
					{stepNo === 2 && <Route path={`/:pageId`} element={<GigBookConfirm />} />}
					{stepNo === 3 && <Route path={`/:pageId`} element={<DealerDetails />} />}
					{stepNo === 4 && <Route path={`/:pageId`} element={<Instructions />} />}
				</Routes>
			</gigContext.Provider>
		</>
	);
};

export default Index;
