import React from "react";
import ReactPaginate from "react-paginate";

const index = (props) => {
  return (
    <div className='pb-4 pt-4'>
      <nav>
        <ReactPaginate
          className='pagination driverPagination justify-content-center mb-5 '
          pageRangeDisplayed={0}
          marginPagesDisplayed={1}
          breakLabel='...'
          {...props}
          pageLabelBuilder={(t) => <button className='page-link mx-1 rounded btn btn-sm btn_primary_black'>{t}</button>}
          nextLabel={
            <button
              className='page-link mx-1 rounded btn btn-sm btn_primary_black'
              style={{ position: "relative", zIndex: "0" }}>
              {">"}
            </button>
          }
          previousLabel={
            <button
              className='page-link mx-1 rounded btn btn-sm btn_primary_black'
              style={{ position: "relative", zIndex: "0" }}>
              {"<"}
            </button>
          }
          renderOnZeroPageCount={null}
        />
      </nav>
    </div>
  );
};

export default index;
