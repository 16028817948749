import React, { useRef, useState } from "react";
import "react-phone-number-input/style.css";
import { assignBucketUrl, handleFileUpload } from "../../../HelperFunctions";
import { ImgLoader } from "../../../Utils/Loader";
import Input from "react-phone-number-input/input";
import AutofillAddress from "./AutofillAddress";
import ProfilePicDemo from "../../../Assets/images/image_2.jpeg";
import { InputErrorMessage } from "../../../Components/ErrorMessage";

function Form2({ children, formik2 }) {
	const [loader, setLoader] = useState(false);
	const [loader2, setLoader2] = useState(false);

	const imgRef = useRef();
	const imgRef1 = useRef();
	const inputRef = useRef();
	// const inputRef1 = useRef();

	const handleChangePhoneInput = (e) => {
		formik2.setFieldValue("phone", e);
	};

	const handleRemove = (name) => {
		formik2.setFieldValue(name, "");
		// inputRef.current.src = ProfilePicDemo;
	};

	// const cityOptions =
	//   cityData?.length > 0 &&
	//   cityData?.map((item) => {
	//     const container = {};
	//     container["value"] = item.stateCode;
	//     container["label"] = `${item.name}`;
	//     return container;
	//   });

	// const cityHandleClick = () => {
	//   if (formik2?.values.state == "" || formik2?.state?.length === 0) {
	//     toast.error("Please select the state first", { id: "001" });
	//   }
	// };

	return (
		<>
			<p className='text-muted mb-4 fw-semibold p-16'>Please fill in your Details Below.</p>
			<form onSubmit={formik2.handleSubmit}>
				<div className='mb-3'>
					<Input
						className={
							Boolean(formik2.touched?.phone && formik2.errors?.phone)
								? "form-control border-danger p-3 cu-45 e-caret-hide "
								: "cu-45 form-control p-3 e-caret-hide "
						}
						country="US"
						countrySelectProps={{ unicodeFlags: true }}
						initialValueFormat="national"		

						defaultCountry='US'
						countries={["US"]}
						name='phone'
						placeholder=' Cell phone number'
						value={formik2.values.phone}
						onChange={handleChangePhoneInput}
						international={false}
					/>
					<InputErrorMessage error={formik2.touched.phone && formik2.errors.phone} />
				</div>

				<AutofillAddress regFormik2={formik2} />

				<div className='mb-4'>
					<input
						className={
							Boolean(formik2.touched?.drivingLicenseNo && formik2.errors?.drivingLicenseNo)
								? "form-control border-danger p-3 cu-45 e-caret-hide "
								: "cu-45 form-control p-3 e-caret-hide "
						}
						type='text'
						// className='form-control'
						id='exampleInputEmail1'
						aria-describedby='emailHelp'
						placeholder="Your driver's license number"
						name='drivingLicenseNo'
						value={formik2.values.drivingLicenseNo}
						onChange={formik2.handleChange}
					/>
					<InputErrorMessage error={formik2.touched.drivingLicenseNo && formik2.errors.drivingLicenseNo} />
				</div>
				<p className='text-grey p-14 fw-normal fw-bold'>
					Take photo of front and back of your driver's license ({"DL"})
					
				</p>
				 

				<div className='mb-4 '>
					<div
						className={
							Boolean(formik2.touched?.drivingLicensePicture && formik2.errors?.drivingLicensePicture)
								? " input-group d-flex justify-content-between align-items-center text-align-center py-1 form-control pe-1 border-danger   "
								: "input-group d-flex justify-content-between align-items-center text-align-center py-1 form-control pe-1"
						}
						
					>
						<div className='text-align-center  py-1' style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden", paddingRight: "60px" }}>
							<h6 className='text-fade1  mt-2 overflow-x-auto overflow-y-auto' style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden" }}>
								{formik2?.values?.drivingLicensePicture ? formik2?.values?.drivingLicensePicture?.split("/").slice(-1)[0] : "Front of your DL"}
							</h6>
						</div>
						
						<label htmlFor='licenseFile' className='position-absolute end-0'>
							<div
								className='text-fade border p-2 rounded bg me-1'
								onClick={() => {
									imgRef?.current?.click();
								}}
								style={{ cursor: "pointer" }}>
								<i className='bi bi-camera-fill'></i>
							</div>
						</label>

						<input
							type='file'
							id='licenseFile'
							name='drivingLicensePicture'
							className='form-control d-flex justify-content-end d-none'
							onChange={(e) => {
								handleFileUpload(e, formik2, setLoader2);
							}}
							placeholder=''
							accept='image/*'
						/>
					</div>
					<InputErrorMessage error={formik2.touched.drivingLicensePicture && formik2.errors.drivingLicensePicture} />
				</div>
				<div className='mb-4'>
					 

					<div
						className={`${
							formik2.touched?.drivingLicensePictureBack && formik2.errors?.drivingLicensePictureBack && "border-danger"
						}  input-group d-flex justify-content-between align-items-center text-align-center py-1 form-control pe-1`}
					 
					>
						<div className='text-align-center   py-1' style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden", paddingRight: "60px" }}>
							<h6 className='text-fade1  mt-2 overflow-x-auto overflow-y-auto' style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden" }}>
								{formik2?.values?.drivingLicensePictureBack ? formik2?.values?.drivingLicensePictureBack?.split("/")?.slice(-1)[0] : "Back of your DL"}
							</h6>
						</div>
						<div style={{ paddingRight: "5rem" }}>
						 
						</div>

						<label htmlFor='licenseFile1' className='position-absolute end-0'>
							<div
								className='text-fade border p-2 rounded bg  me-1'
								onClick={() => {
									imgRef1?.current?.click();
								}}
								style={{ cursor: "pointer" }}>
								<i className='bi bi-camera-fill'></i>
							</div>
						</label>
						<input
							type='file'
							id='licenseFile1'
							name='drivingLicensePictureBack'
							className='form-control d-flex justify-content-end d-none'
							onChange={(e) => {
								handleFileUpload(e, formik2, setLoader2);
							}}
							placeholder=''
							accept='image/*'
						/>
					</div>
					<InputErrorMessage error={formik2.touched.drivingLicensePictureBack && formik2.errors.drivingLicensePictureBack} />
				</div>
				 

				<div>
					<h6 className='fw-semibold'>Profile picture</h6>
					<div className='d-flex justify-content-between align-items-center mb-3 '>
						<div style={{ width: " 50px", height: " 50px", position: "relative" }} className='image-section'>
							<i className='bi bi-x-circle r-0' style={{ cursor: "pointer" }} onClick={() => handleRemove("profilePicture")}></i>

							{formik2?.values?.profilePicture !== "" ? (
								<>
									<img
										ref={inputRef}
										src={assignBucketUrl(formik2?.values?.profilePicture)}
										onError={(event) => {
											event.target.src = "https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg";
											event.onerror = null;
										}}
										alt='#'
										style={{ width: "100%", height: "100%", objectFit: "cover" }}
										className='border rounded-3 '
									/>
								</>
							) : loader ? (
								<ImgLoader />
							) : (
								<img src={ProfilePicDemo} alt='#' style={{ width: "100%", height: "100%", objectFit: "cover" }} className='border rounded-3 ' />
							)}
						</div>
						<label htmlFor='profilePic'>
							<div
								onClick={() => {
									imgRef?.current?.click();
								 
								}}
								style={{ cursor: "pointer" }}
								className={
									Boolean(formik2.touched?.profilePicture && formik2.errors?.profilePicture)
										? "btn1 py-1 h-100 rounded-2 border-danger text-danger"
										: "btn1 py-1 h-100 rounded-2"
								}
							 
							>
								Upload
							</div>
						</label>
						<input
							type='file'
							id='profilePic'
							name='profilePicture'
							onChange={(e) => {
								handleFileUpload(e, formik2, setLoader);
							}}
							className='d-none'
							accept='image/*'
						/>
					</div>
					<p className={`mt-2 fw-bold `} style={{ fontSize: "14px", color: "rgba(0,0,0,0.37)  " }}>
						For a good profile photo, avoid hats and sunglasses, show your full face and top of shoulders, use good lighting, and avoid filters or photoshopped
						images.
					</p>
					<InputErrorMessage error={formik2.touched.profilePicture && formik2.errors.profilePicture} />
				</div>
				<div className='text-center my-4'>{children}</div>
				<div className='d-flex mb-3'>
					<span>
						<p className='my-auto text-black fw-semibold d-inline '> By signing up, </p>
						<p className='fw-semibold d-inline text-black '>I agree 101 driver's privacy policy.</p>
					</span>
				</div>
				<InputErrorMessage error={formik2.touched.signinPolicy && formik2.errors.signinPolicy} />
			</form>
		</>
	);
}
export default Form2;
