import React from "react";
import ResetForm from "./ResetForm";
import ResetThankyou from "./Reset_Thankyou";
import logo from "../../../Assets/images/Group 3621.png";

function ResetPassword() {
  return (
    <div className='container px-3'>
      <div style={{ height: "100px", width: "100px" }} className=' py-3'>
        <img src={logo} alt='#' style={{ width: "100%" }} />
      </div>
      <ResetForm />
      {/* <ResetThankyou /> */}
    </div>
  );
}

export default ResetPassword;
